import { Token } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import { SimpleMap } from 'js/utils'
export interface TokenWithSupply extends Token {
  totalSupply: string
}


export const getBridgeName = (bridgeId: string) => {
  switch (parseInt(bridgeId, 10)) {
    case 0: return 'N/A'
    case 1: return 'PolyNetwork'
    case 2: return 'IBC'
    case 3: return 'Axelar'
    default: return 'Unknown'
  }
}

export const formatPoolTokenNameAndWeights = (token: TokenWithSupply, tokens: SimpleMap<Token>) => {
  // perp pools have correct naming
  if (token.denom.startsWith('cplt/')) return [token.name, '—']

  // split up spot pools into token weights and names: [[w0,t0], [w1,t1]]
  const underlying = token.name.split(' / ')
  if (underlying.length !== 2) return [token.name, '?']
  const parts = underlying.map(t => t.split(' '))

  // look up underlying denom if it is a ibc/x, brdg/x or cgt/x token
  let [t0, t1] = [parts[0][1], parts[1][1]]
  const getSymbol = (name: string) => {
    let denom = name.toLowerCase()
    if (name.startsWith('IBC/')) { // ibc denoms uses caps for the hex part but we don't 🤔
      denom = `ibc/${name.substring(4)}`
    }
    return tokens[denom]?.symbol ?? name
  }

  return [`${getSymbol(t0)}-${getSymbol(t1)}`, `${parts[1][0]} - ${parts[1][0]}`]
}