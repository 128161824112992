import { Box, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { QueryGetLiquidationEngineResponse } from 'carbon-js-sdk/lib/insights'
import { DataLoadSegment, ListTable, PaginationByOffset, Section, TableEmptyState } from 'js/components'
import { SimpleMap } from 'js/utils'
import React, { ReactElement } from 'react'
import LiquidationEngineRow from './LiquidationEngineRow'
import { Market } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/market'

export interface LiquidationEngineProps {
  filterStatus: boolean
  orders?: QueryGetLiquidationEngineResponse
  title: string
  action: any
  markets: SimpleMap<Market>
  loadName: string[]
  loadCondition?: boolean
  sectionClass?: string
}

const LiquidationEngine: React.FunctionComponent<LiquidationEngineProps> = ({
  filterStatus = false,
  orders,
  title,
  action,
  markets,
  loadCondition = true,
  sectionClass,
}: LiquidationEngineProps): ReactElement<LiquidationEngineProps> => {
  // const [loading] = useTaskSubscriber(...loadName)

  return (
    <Section className={sectionClass} title={title}>
      <DataLoadSegment
        filter={filterStatus}
        loading={!loadCondition}
      >
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Order ID</TableCell>
              <TableCell>Market</TableCell>
              <TableCell>To Close</TableCell>
              {/* <TableCell>Status</TableCell> */}
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Bankruptcy Price</TableCell>
              <TableCell align="right">Liquidated Price</TableCell>
              <TableCell align="right">Liquidated Value</TableCell>
              <TableCell align="right">Insurance Fund</TableCell>
              <TableCell align="right">Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders?.entries?.map((order) => (
              <LiquidationEngineRow key={order.id} order={order} markets={markets} />
            ))}
          </TableBody>
        </ListTable>
        {!!orders?.entries?.length && (
          <Box marginTop={3}>
            <PaginationByOffset
              response={orders}
              action={action}
            // filters={{}}
            // useBlockHeight={false}
            />
          </Box>
        )}
        {!orders?.entries?.length && (
          <TableEmptyState itemName={title.toLowerCase()} />
        )}
      </DataLoadSegment>
    </Section>
  )
}

export default LiquidationEngine
