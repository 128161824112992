import { makeStyles, Theme } from '@material-ui/core'
import { TokenClient } from 'carbon-js-sdk/lib/clients'
import { TableSection } from 'js/components'
import Page from 'js/components/Page'
import { TaskNames } from 'js/constants'
import { useRedux } from 'js/hooks'
import React, { ReactElement, useMemo } from 'react'
import { TokenInfoRow, PoolTokenInfoRow } from './components'
import { TokenWithSupply } from './components/TokenInfoRow'
import BigNumber from 'bignumber.js'

interface Props { }

const TokenList: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const classes = useStyles()
  const { balances, info } = useRedux((state) => state.tokens)

  const { tokens, poolTokens } = useMemo(() => {
    const tokensWithSupply = info.map(t => ({ totalSupply: new BigNumber(balances[t.denom]?.total || 0).shiftedBy(-t.decimals.toNumber()).toString(), ...t }))

    const sorted = tokensWithSupply.sort((a, b) => {
      const aDeprecated = a.isDeprecated ? 1 : 0;
      const bDeprecated = b.isDeprecated ? 1 : 0;
      const aPerp = a.name.endsWith('(Perp)') ? 1 : 0;
      const bPerp = b.name.endsWith('(Perp)') ? 1 : 0;
      const aIsPool = TokenClient.isPoolToken(a.denom) || a.denom.startsWith('cplt/') ? 1 : 0;
      const bIsPool = TokenClient.isPoolToken(b.denom) || b.denom.startsWith('cplt/') ? 1 : 0;

      // Deprecated tokens go to the back
      if (aDeprecated !== bDeprecated) return aDeprecated - bDeprecated;

      // If both are deprecated, ensure (Perp) is last among them
      if (aDeprecated === 1 && bDeprecated === 1 && aPerp !== bPerp) return aPerp - bPerp;

      // (Perp) tokens should be just before deprecated tokens
      if (aPerp !== bPerp) return aPerp - bPerp;

      // If both tokens are pool tokens, sort by createdBlockHeight
      if (aIsPool || bIsPool) return b.createdBlockHeight.compare(a.createdBlockHeight);

      // Otherwise, sort by symbol alphabetically
      return a.symbol.localeCompare(b.symbol);
    })

    return sorted.reduce((result, token) => {
      if (TokenClient.isPoolToken(token.denom) || token.denom.startsWith('cplt/')) {
        result.poolTokens.push(token)
      } else {
        result.tokens.push(token)
      }

      return result
    }, { tokens: [] as TokenWithSupply[], poolTokens: [] as TokenWithSupply[] })
  }, [balances, info])

  return (
    <Page title="Tokens">
      <TableSection
        cellClass={classes.cellClass}
        headerCells={[
          {
            align: "left",
            content: "Name (Symbol)",
          },
          {
            align: 'left',
            content: 'Chain',
          },
          {
            align: "left",
            content: 'Bridge (ID)',
          },
          {
            align: "right",
            content: 'Decimals',
          },
          {
            align: "right",
            content: 'Total Supply',
          }
        ]}
        loadName={TaskNames.Tokens.Info}
        models={tokens}
        rowComponent={TokenInfoRow}
        title="Bridged Tokens"
        itemName="tokens"
        pagination
        searchBar
        searchKeys={["denom", "symbol"]}
        placeholder="Search for token denom or symbol (eg. USDC)"
      />
      <TableSection
        cellClass={classes.cellClass}
        headerCells={[
          {
            align: "left",
            content: "Name (Symbol)",
          },
          {
            align: 'right',
            content: 'Weight',
          },
          {
            align: "right",
            content: 'Total Supply',
          }
        ]}
        loadName={TaskNames.Tokens.Info}
        models={poolTokens}
        rowComponent={PoolTokenInfoRow}
        title="Pool Tokens"
        itemName="tokens"
        pagination
        searchBar
        searchKeys={["denom", "symbol"]}
        placeholder="Search for token denom or symbol (eg. USDC)"
      />
    </Page>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  cellClass: {
    padding: '0.5rem 1.15rem 0.5rem 0.7rem',
    whiteSpace: 'nowrap',
  },
}))

export default TokenList
