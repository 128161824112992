import { Blockchain } from "carbon-js-sdk";
import { Token } from "carbon-js-sdk/lib/codec/Switcheo/carbon/coin/export";
import { CrossChainTransferDetailed } from "carbon-js-sdk/lib/hydrogen";
import { BLOCKCHAIN_V2_TO_V1_MAPPING } from "carbon-js-sdk/lib/util/blockchain";
import { BlockChainPaths } from "js/constants";
import { shortenHash } from "js/helpers";
import { SimpleMap, ValueOf, reverseStr } from "js/utils";

export interface CrossChainMappedDetails {
  sourceTxHashDisplay: string;
  destinationTxHashDisplay: string;
  sourceTxUrl: string;
  sourceBlockHeightUrl: string;
  sourceAssetUrl: string;
  sourceAddressUrl: string;
  bridgingTxUrl: string;
  destinationTxUrl: string;
  destinationBlockHeightUrl: string;
  destinationAssetUrl: string;
  destinationAddressUrl: string;
}

export interface BridgeChainParamMap {
  bridgingTxUrlParam: (bridgingHash?: string) => string;
}

export interface SourceDestChainParamMap {
  sourceTxHashDisplay?: (sourceTxHashDisplay: string) => string;
  txUrlParams: (txHash: string | undefined) => string;
  blockHeightUrlParams: (txBlockHeight: number) => string;
  sourceAssetUrlParams: (asset: string, asset_hash: string, matchToken: Token | undefined) => string;
  sourceAddressUrlParams: (address: string, address_hash: string) => string;
  destinationAssetUrlParams: (asset: string, asset_hash: string, matchToken: Token | undefined) => string;
  destinationAddressUrlParams: (address: string, address_hash: string) => string;
}

const BridgingUrlParamMaps: SimpleMap<BridgeChainParamMap> = {
  [Blockchain.PolyNetwork]: {
    bridgingTxUrlParam: (bridgingHash) => `/tx/${bridgingHash?.replace('0x', '')}`
  },
  [Blockchain.Ibc]: {
    bridgingTxUrlParam: (bridgingHash) => `/transfers/details?hash=${bridgingHash?.replace('0x', '')}`
  },
  [Blockchain.Axelar]: {
    bridgingTxUrlParam: (bridgingHash) => `/txs/${bridgingHash?.replace('0x', '')}`
  }
}

const UrlParamMaps: SimpleMap<SourceDestChainParamMap> = {
  [Blockchain.Neo3]: {
    txUrlParams: (txHash) => `/transaction/${txHash}`,
    blockHeightUrlParams: (txBlockHeight) => `/block/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => {
      return matchToken?.denom === 'nneo1'
        ? `/tokens/nep17/${asset}`
        : `/tokens/nep17/${reverseStr(matchToken?.tokenAddress ?? "", 2)}`
    },
    sourceAddressUrlParams: (address, address_hash) => `/address/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => {
      return matchToken?.denom === 'nneo1'
        ? `/tokens/nep17/${asset}`
        : `/tokens/nep17/${reverseStr(matchToken?.tokenAddress ?? "", 2)}`
    },
    destinationAddressUrlParams: (address, address_hash) => `/address/${address_hash}`
  },
  [Blockchain.Neo]: {
    txUrlParams: (txHash) => `/transaction/${txHash}`,
    blockHeightUrlParams: (txBlockHeight) => `/block/${txBlockHeight}/page/1`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => {
      return matchToken?.denom === 'nneo1'
        ? `/tokens/nep5/${asset}/page/1`
        : `/tokens/nep5/${reverseStr(matchToken?.tokenAddress ?? "", 2)}/page/1`
    },
    sourceAddressUrlParams: (address, address_hash) => `/address/${address}/page/1`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => {
      return matchToken?.denom === 'nneo1'
        ? `/tokens/nep5/${asset}`
        : `/tokens/nep5/${reverseStr(matchToken?.tokenAddress ?? "", 2)}/page/1`
    },
    destinationAddressUrlParams: (address, address_hash) => `/address/${address_hash}/page/1`
  },
  [Blockchain.Ethereum]: {
    txUrlParams: (txHash) => `/tx/${txHash}`,
    blockHeightUrlParams: (txBlockHeight) => `/block/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/token/${asset}`,
    sourceAddressUrlParams: (address, address_hash) => `/address/${address_hash}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/token/${asset_hash}`,
    destinationAddressUrlParams: (address, address_hash) => `/address/${address_hash}`
  },
  [Blockchain.Polygon]: {
    txUrlParams: (txHash) => `/tx/${txHash}`,
    blockHeightUrlParams: (txBlockHeight) => `/block/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/token/${asset}`,
    sourceAddressUrlParams: (address, address_hash) => `/address/${address_hash}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `token/${asset_hash}`,
    destinationAddressUrlParams: (address, address_hash) => `/address/${address_hash}`
  },
  [Blockchain.Zilliqa]: {
    txUrlParams: (txHash) => `/tx/${txHash}`,
    blockHeightUrlParams: (txBlockHeight) => `/block/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/address/${asset_hash}`,
    sourceAddressUrlParams: (address, address_hash) => `/address/${address_hash}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/address/${asset_hash}`,
    destinationAddressUrlParams: (address, address_hash) => `/address/${address_hash}`

  },
  [Blockchain.BinanceSmartChain]: {
    txUrlParams: (txHash) => `/tx/${txHash}`,
    blockHeightUrlParams: (txBlockHeight) => `/block/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/token/${asset}`,
    sourceAddressUrlParams: (address, address_hash) => `/address/${address_hash}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/token/${asset_hash}`,
    destinationAddressUrlParams: (address, address_hash) => `/address/${address_hash}`
  },
  [Blockchain.Osmosis]: {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/txs/${txHash?.replace('0x', '')}`,
    blockHeightUrlParams: (txBlockHeight) => `/blocks/id/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    sourceAddressUrlParams: (address, address_hash) => `/account/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    destinationAddressUrlParams: (address, address_hash) => `/account/${address}`
  },
  [Blockchain.Juno]: {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/txs/${txHash?.replace('0x', '')}`,
    blockHeightUrlParams: (txBlockHeight) => `/blocks/id/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    sourceAddressUrlParams: (address, address_hash) => `/account/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    destinationAddressUrlParams: (address, address_hash) => `/account/${address}`
  },
  [Blockchain.CosmosHub]: {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/txs/${txHash?.replace('0x', '')}`,
    blockHeightUrlParams: (txBlockHeight) => `/blocks/id/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    sourceAddressUrlParams: (address, address_hash) => `/account/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    destinationAddressUrlParams: (address, address_hash) => `/account/${address}`
  },
  [Blockchain.Evmos]: {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/txs/${txHash?.replace('0x', '')}`,
    blockHeightUrlParams: (txBlockHeight) => `/blocks/id/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    sourceAddressUrlParams: (address, address_hash) => `/account/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    destinationAddressUrlParams: (address, address_hash) => `/account/${address}`
  },
  [Blockchain.Axelar]: {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/txs/${txHash?.replace('0x', '')}`,
    blockHeightUrlParams: (txBlockHeight) => `/blocks/id/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    sourceAddressUrlParams: (address, address_hash) => `/account/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    destinationAddressUrlParams: (address, address_hash) => `/account/${address}`
  },
  'noble': {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/txs/${txHash?.replace('0x', '')}`,
    blockHeightUrlParams: (txBlockHeight) => `/blocks/id/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    sourceAddressUrlParams: (address, address_hash) => `/account/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    destinationAddressUrlParams: (address, address_hash) => `/account/${address}`
  },
  [Blockchain.Stride]: {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/txs/${txHash?.replace('0x', '')}`,
    blockHeightUrlParams: (txBlockHeight) => `/blocks/id/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    sourceAddressUrlParams: (address, address_hash) => `/account/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    destinationAddressUrlParams: (address, address_hash) => `/account/${address}`
  },
  [Blockchain.Kujira]: {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/txs/${txHash?.replace('0x', '')}`,
    blockHeightUrlParams: (txBlockHeight) => `/blocks/id/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    sourceAddressUrlParams: (address, address_hash) => `/account/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    destinationAddressUrlParams: (address, address_hash) => `/account/${address}`
  },
  [Blockchain.Comdex]: {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/txs/${txHash?.replace('0x', '')}`,
    blockHeightUrlParams: (txBlockHeight) => `/blocks/id/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    sourceAddressUrlParams: (address, address_hash) => `/account/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    destinationAddressUrlParams: (address, address_hash) => `/account/${address}`
  },
  [Blockchain.StafiHub]: {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/txs/${txHash?.replace('0x', '')}`,
    blockHeightUrlParams: (txBlockHeight) => `/blocks/id/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    sourceAddressUrlParams: (address, address_hash) => `/account/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    destinationAddressUrlParams: (address, address_hash) => `/account/${address}`
  },
  [Blockchain.Persistence]: {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/txs/${txHash?.replace('0x', '')}`,
    blockHeightUrlParams: (txBlockHeight) => `/blocks/id/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    sourceAddressUrlParams: (address, address_hash) => `/account/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    destinationAddressUrlParams: (address, address_hash) => `/account/${address}`
  },
  [Blockchain.Stargaze]: {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/txs/${txHash?.replace('0x', '')}`,
    blockHeightUrlParams: (txBlockHeight) => `/blocks/id/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    sourceAddressUrlParams: (address, address_hash) => `/account/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    destinationAddressUrlParams: (address, address_hash) => `/account/${address}`
  },
  [Blockchain.Quicksilver]: {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/txs/${txHash?.replace('0x', '')}`,
    blockHeightUrlParams: (txBlockHeight) => `/blocks/id/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    sourceAddressUrlParams: (address, address_hash) => `/account/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    destinationAddressUrlParams: (address, address_hash) => `/account/${address}`
  },
  [Blockchain.OmniFlixHub]: {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/txs/${txHash?.replace('0x', '')}`,
    blockHeightUrlParams: (txBlockHeight) => `/blocks/id/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    sourceAddressUrlParams: (address, address_hash) => `/account/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    destinationAddressUrlParams: (address, address_hash) => `/account/${address}`
  },
  [Blockchain.Canto]: {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/transactions/${txHash?.replace('0x', '')}`,
    blockHeightUrlParams: (txBlockHeight) => `/blocks/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    sourceAddressUrlParams: (address, address_hash) => `/account/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    destinationAddressUrlParams: (address, address_hash) => `/account/${address}`
  },
  [Blockchain.Agoric]: {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/transactions/${txHash?.replace('0x', '')}`,
    blockHeightUrlParams: (txBlockHeight) => `/blocks/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    sourceAddressUrlParams: (address, address_hash) => `/account/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    destinationAddressUrlParams: (address, address_hash) => `/account/${address}`
  },
  [Blockchain.Terra]: {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/tx/${txHash?.replace('0x', '')}`,
    blockHeightUrlParams: (txBlockHeight) => `/blocks/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/`,
    sourceAddressUrlParams: (address, address_hash) => `/address/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/`,
    destinationAddressUrlParams: (address, address_hash) => `/address/${address}`
  },
  [Blockchain.Terra2]: {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/tx/${txHash?.replace('0x', '')}`,
    blockHeightUrlParams: (txBlockHeight) => `/blocks/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/`,
    sourceAddressUrlParams: (address, address_hash) => `/address/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/`,
    destinationAddressUrlParams: (address, address_hash) => `/address/${address}`
  },
  [Blockchain.Sommelier]: {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/txs/${txHash?.replace('0x', '')}`,
    blockHeightUrlParams: (txBlockHeight) => `/blocks/id/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    sourceAddressUrlParams: (address, address_hash) => `/account/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    destinationAddressUrlParams: (address, address_hash) => `/account/${address}`
  },
  "decentr": {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/transactions/${txHash?.replace('0x', '')}`,
    blockHeightUrlParams: (txBlockHeight) => `/blocks/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    sourceAddressUrlParams: (address, address_hash) => `/account/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/assets`,
    destinationAddressUrlParams: (address, address_hash) => `/account/${address}`
  },
  [Blockchain.Arbitrum]: {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/tx/${txHash}`,
    blockHeightUrlParams: (txBlockHeight) => `/block/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/`,
    sourceAddressUrlParams: (address, address_hash) => `/address/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/`,
    destinationAddressUrlParams: (address, address_hash) => `/address/${address}`
  },
  [Blockchain.Carbon]: {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/transaction/${txHash?.replace('0x', '')}`,
    blockHeightUrlParams: (txBlockHeight) => `/block/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/token/${encodeURIComponent(matchToken?.denom ?? "")}`,
    sourceAddressUrlParams: (address, address_hash) => `/address/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/token/${encodeURIComponent(matchToken?.denom ?? "")}`,
    destinationAddressUrlParams: (address, address_hash) => `/address/${address}`
  },
  [Blockchain.Mantle]: {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/tx/${txHash}`,
    blockHeightUrlParams: (txBlockHeight) => `/block/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/token/${encodeURI(matchToken?.denom ?? "")}`,
    sourceAddressUrlParams: (address, address_hash) => `/address/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/token/${encodeURI(matchToken?.denom ?? "")}`,
    destinationAddressUrlParams: (address, address_hash) => `/address/${address}`
  },
  [Blockchain.Optimism]: {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/tx/${txHash}`,
    blockHeightUrlParams: (txBlockHeight) => `/block/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/token/${encodeURI(matchToken?.denom ?? "")}`,
    sourceAddressUrlParams: (address, address_hash) => `/address/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/token/${encodeURI(matchToken?.denom ?? "")}`,
    destinationAddressUrlParams: (address, address_hash) => `/address/${address}`
  },
  [Blockchain.Base]: {
    sourceTxHashDisplay: (sourceTxHashDisplay) => sourceTxHashDisplay.replace('0x', ''),
    txUrlParams: (txHash) => `/tx/${txHash}`,
    blockHeightUrlParams: (txBlockHeight) => `/block/${txBlockHeight}`,
    sourceAssetUrlParams: (asset, asset_hash, matchToken) => `/token/${encodeURI(matchToken?.denom ?? "")}`,
    sourceAddressUrlParams: (address, address_hash) => `/address/${address}`,
    destinationAssetUrlParams: (asset, asset_hash, matchToken) => `/token/${encodeURI(matchToken?.denom ?? "")}`,
    destinationAddressUrlParams: (address, address_hash) => `/address/${address}`
  }
}

const getBasePath = (blockchain: Blockchain | string | null, paths: ValueOf<typeof BlockChainPaths>) => {
  switch (blockchain) {
    case Blockchain.Carbon: return ':path'
    case "decentr": return 'https://atomscan.com/decentr:path'
    case Blockchain.BinanceSmartChain: return paths.bsc
    case Blockchain.Neo3: return paths.neo3
    case Blockchain.Neo: return paths.neo
    case Blockchain.Ethereum: return paths.eth
    case Blockchain.Polygon: return paths.polygon
    case Blockchain.Zilliqa: return paths.zil
    case Blockchain.Osmosis: return paths.osmosis
    case Blockchain.Juno: return paths.juno
    case Blockchain.CosmosHub: return paths.cosmoshub
    case Blockchain.Evmos: return paths.evmos
    case Blockchain.Axelar: return paths.axelar
    case Blockchain.Stride: return paths.stride
    case Blockchain.Kujira: return paths.kujira
    case Blockchain.Comdex: return paths.comdex
    case Blockchain.StafiHub: return paths.stafi
    case Blockchain.Persistence: return paths.persistence
    case Blockchain.Stargaze: return paths.stargaze
    case Blockchain.Quicksilver: return paths.quicksilver
    case Blockchain.OmniFlixHub: return paths.omniflixhub
    case Blockchain.Canto: return paths.canto
    case Blockchain.Agoric: return paths.agoric
    case Blockchain.Terra: return paths.terra
    case Blockchain.PolyNetwork: return paths.polynetwork
    case Blockchain.Ibc: return paths.ibc
    case Blockchain.Sommelier: return paths.sommelier
    case Blockchain.Arbitrum: return paths.arbitrum
    case Blockchain.Mantle: return paths.mantle
    case Blockchain.Optimism: return paths.optimism
    case Blockchain.Base: return paths.base
    case 'noble': return paths.noble
    default: return ""
  }
}

export const getV1ChainMapping = (blockchain?: string) => {
  if (!blockchain) {
    return ""
  }
  blockchain = BLOCKCHAIN_V2_TO_V1_MAPPING[blockchain] ?? blockchain
  switch (blockchain) {
    case "Persistence Core": return Blockchain.Persistence
    case "Omniflix Hub": return Blockchain.OmniFlixHub
    case "Stafihub": return Blockchain.StafiHub
    case "Cosmos Hub": return Blockchain.CosmosHub
    case "Native": return Blockchain.Carbon
    default: return blockchain.toLowerCase()
  }
}

export const getCrossChainMappedDetails = (crossChainTransferDetails: CrossChainTransferDetailed, paths: ValueOf<typeof BlockChainPaths>, matchToken?: Token): CrossChainMappedDetails => {
  const {
    from_address,
    from_address_hash,
    from_asset,
    from_asset_hash,
    to_address,
    to_address_hash,
    to_asset,
    to_asset_hash,
    source_event,
    source_blockchain,
    bridging_event,
    bridging_blockchain,
    destination_event,
    destination_blockchain,
  } = crossChainTransferDetails

  const sourceTxHash = source_event?.tx_hash
  const sourceTxBlockHeight = source_event?.block_height ?? 0
  const bridgingTxHash = bridging_event?.tx_hash
  const destinationTxHash = destination_event?.tx_hash
  const destinationTxBlockHeight = destination_event?.block_height ?? 0
  let sourceBlockchain = (source_event?.blockchain ?? source_blockchain) ?? ""
  let bridgingBlockchain = (bridging_event?.blockchain ?? bridging_blockchain) ?? ""
  let destinationBlockchain = (destination_event?.blockchain ?? destination_blockchain) ?? ""

  const sourceBlockchainV1 = getV1ChainMapping(sourceBlockchain)
  const bridgingBlockchainV1 = getV1ChainMapping(bridgingBlockchain)
  const destinationBlockchainV1 = getV1ChainMapping(destinationBlockchain)

  const sourceChainPath = getBasePath(sourceBlockchainV1, paths)
  const sourceChainUrlMap = UrlParamMaps[sourceBlockchainV1]
  const destChainPath = getBasePath(destinationBlockchainV1, paths)
  const destChainUrlMap = UrlParamMaps[destinationBlockchainV1]
  const bridgingChainPath = getBasePath(bridgingBlockchainV1, paths)
  const bridgingChainUrlMap = BridgingUrlParamMaps[bridgingBlockchainV1]

  const bridgingHash = bridgingTxHash ?? sourceTxHash
  const bridgingTxUrl = bridgingChainPath.replace(':path', bridgingChainUrlMap?.bridgingTxUrlParam(bridgingHash) ?? "")

  let sourceTxUrl = sourceChainPath.replace(':path', sourceChainUrlMap?.txUrlParams(sourceTxHash) ?? "")
  let sourceBlockHeightUrl = sourceChainPath.replace(':path', sourceChainUrlMap?.blockHeightUrlParams(sourceTxBlockHeight) ?? "")
  let sourceAssetUrl = sourceChainPath.replace(':path', sourceChainUrlMap?.sourceAssetUrlParams(from_asset, from_asset_hash, matchToken) ?? "")
  let sourceAddressUrl = sourceChainPath.replace(':path', sourceChainUrlMap?.sourceAddressUrlParams(from_address, from_address_hash) ?? "")

  let destinationTxUrl = destChainPath.replace(':path', destChainUrlMap?.txUrlParams(destinationTxHash) ?? "")
  let destinationBlockHeightUrl = destChainPath.replace(':path', destChainUrlMap?.blockHeightUrlParams(destinationTxBlockHeight) ?? "")
  let destinationAssetUrl = destChainPath.replace(':path', destChainUrlMap?.destinationAssetUrlParams(to_asset, to_asset_hash, matchToken) ?? "")
  let destinationAddressUrl = destChainPath.replace(':path', destChainUrlMap?.destinationAddressUrlParams(to_address, to_address_hash) ?? "")


  let destinationTxHashDisplay = destinationTxHash ?? ''
  let sourceTxHashDisplay = sourceTxHash ?? ''
  if (sourceChainUrlMap?.sourceTxHashDisplay) {
    sourceTxHashDisplay = sourceChainUrlMap.sourceTxHashDisplay(sourceTxHashDisplay)
  } else if (destChainUrlMap?.sourceTxHashDisplay) {
    sourceTxHashDisplay = destChainUrlMap.sourceTxHashDisplay(sourceTxHashDisplay)
  }

  if (destinationBlockchain === Blockchain.Carbon) {
    destinationTxHashDisplay = destinationTxHashDisplay.replace('0x', '')
  }

  return {
    sourceTxHashDisplay, destinationTxHashDisplay, bridgingTxUrl,
    sourceTxUrl, sourceBlockHeightUrl, sourceAssetUrl, sourceAddressUrl,
    destinationTxUrl, destinationBlockHeightUrl, destinationAssetUrl, destinationAddressUrl
  }
}

export const processCrossChainTransferDetails = (crossChainTransferDetails: CrossChainTransferDetailed, tokensMap: SimpleMap<Token>) => {
  const { source_event, bridging_event, destination_event,
    source_blockchain, bridging_blockchain, destination_blockchain,
    to_asset, from_asset, from_address, to_address } = crossChainTransferDetails

  const sourceTxHash = source_event?.tx_hash
  const sourceTxBlockHeight = source_event?.block_height ?? 0
  const bridgingTxHash = bridging_event?.tx_hash
  const destinationTxHash = destination_event?.tx_hash
  const destinationTxBlockHeight = destination_event?.block_height ?? 0
  const blocktime = source_event?.block_time ?? bridging_event?.block_time ?? destination_event?.block_time
  let sourceBlockchain = (source_event?.blockchain ?? source_blockchain) ?? ""
  let bridgingBlockchain = (bridging_event?.blockchain ?? bridging_blockchain) ?? ""
  let destinationBlockchain = (destination_event?.blockchain ?? destination_blockchain) ?? ""

  const matchToken = getTokenIfMatch(crossChainTransferDetails, tokensMap)
  const isIBCToken = matchToken?.denom.includes("ibc/")
  const sourceFromCarbon = sourceBlockchain ? sourceBlockchain.toUpperCase() === "CARBON"
    : from_address.includes('swth1') && !(to_address.includes('swth1')) ? true : false
  const sourceDenom =
    isIBCToken ?
      sourceFromCarbon ?
        matchToken?.symbol ?? ""
        : (matchToken?.symbol ?? "").length > 8
          ? shortenHash(matchToken?.symbol, 6)
          : matchToken?.symbol
      : sourceFromCarbon
        ? matchToken?.denom ?? ""
        : to_asset.length > 8
          ? shortenHash(to_asset, 6)
          : to_asset

  const destinationDenom =
    isIBCToken ?
      !sourceFromCarbon
        ? (matchToken?.symbol ?? "").length > 8
          ? shortenHash(matchToken?.symbol, 6)
          : matchToken?.symbol
        : from_asset.length > 8
          ? shortenHash(from_asset, 6)
          : from_asset
      : !sourceFromCarbon
        ? matchToken?.denom ?
          (matchToken?.denom ?? "").length > 8
            ? shortenHash(matchToken?.denom, 6)
            : matchToken?.denom
          : from_asset.length > 8 ? shortenHash(from_asset, 6) : from_asset
        : from_asset.length > 8
          ? shortenHash(from_asset, 6)
          : from_asset


  return {
    sourceTxHash, sourceTxBlockHeight, bridgingTxHash, destinationTxHash, destinationTxBlockHeight,
    sourceBlockchain, bridgingBlockchain, destinationBlockchain,
    blocktime, sourceDenom, destinationDenom, matchToken, sourceFromCarbon
  }
}

export const getBlockTime = (crossChainTransferDetails: CrossChainTransferDetailed) => {
  const { source_event, bridging_event, destination_event } = crossChainTransferDetails
  return source_event?.block_time ?? bridging_event?.block_time ?? destination_event?.block_time
}

const getTokenIfMatch = (crossChainTransferDetails: CrossChainTransferDetailed, tokensMap: SimpleMap<Token>): Token | undefined => {
  const { carbon_token_id, to_asset, to_asset_hash, from_asset, from_asset_hash } = crossChainTransferDetails
  return Object.values(tokensMap).find((token) =>
    token.denom?.toLowerCase() === carbon_token_id?.toLowerCase()) ??
    Object.values(tokensMap).find((token) =>
      token.tokenAddress?.toLowerCase() === to_asset_hash?.toLowerCase() ||
      token.tokenAddress?.toLowerCase() === from_asset_hash?.toLowerCase()
    ) ??
    Object.values(tokensMap).find((token) =>
      token.symbol?.toLowerCase() === to_asset?.toLowerCase() ||
      token.symbol?.toLowerCase() === from_asset?.toLowerCase()
    ) ??
    Object.values(tokensMap).find((token) => {
      return token.denom.split("ibc/")[1]?.toLowerCase() === to_asset_hash?.toLowerCase() || token.denom.split("ibc/")[1]?.toLowerCase() === from_asset_hash?.toLowerCase()
    }
    ) ?? undefined
}