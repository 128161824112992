import { Paths } from 'js/constants'
import React from 'react'
import { SvgDiscord, SvgReddit, SvgTelegram, SvgTwitter } from './icons'

interface FooterLink {
  label: string
  href: string
}

interface SocialBtn {
  label: string
  href: string
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

export const foundationLinks: FooterLink[] = [
  {
    label: 'About Switcheo',
    href: Paths.Foundation.About,
  },
  {
    label: 'Blog',
    href: Paths.Foundation.Blog,
  },
  {
    label: 'Support',
    href: Paths.Foundation.Support,
  },
]

export const ecosystemLinks: FooterLink[] = [
  {
    label: 'Demex',
    href: Paths.EcoSystem.Demex,
  },
  {
    label: 'Hydrogen',
    href: Paths.EcoSystem.Hydrogen,
  },
  // {
  //   label: "Governance",
  //   href: Paths.Governance,
  // },
]

export const resourcesLinks: FooterLink[] = [
  {
    label: 'Developer Docs',
    href: Paths.Resources.API,
  },
  {
    label: 'GitHub',
    href: Paths.Resources.Github,
  },
  // {
  //   label: 'Insights Agent',
  //   href: Paths.Chatbot,
  // },
]

export const socialBtns: SocialBtn[] = [
  {
    label: 'Twitter',
    href: Paths.Social.Twitter,
    icon: SvgTwitter,
  },
  {
    label: 'Telegram',
    href: Paths.Social.Telegram,
    icon: SvgTelegram,
  },
  {
    label: 'Discord',
    href: Paths.Social.Discord,
    icon: SvgDiscord,
  },
  {
    label: 'Reddit',
    href: Paths.Social.Reddit,
    icon: SvgReddit,
  },
]
