"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSignaturesFromInvocationScript = exports.getSigningThresholdFromVerificationScript = exports.getPublicKeysFromVerificationScript = exports.constructMultiSigVerificationScript = void 0;
const sc_1 = require("../sc");
const u_1 = require("../u");
const verify_1 = require("./verify");
function constructMultiSigVerificationScript(signingThreshold, keys) {
    if (signingThreshold > keys.length) {
        throw new Error("signingThreshold must be smaller than or equal to number of keys");
    }
    const ss = new sc_1.ScriptBuilder();
    ss.emitPush(signingThreshold);
    keys.forEach((k) => {
        if (!verify_1.isPublicKey(k, true)) {
            throw new Error(`${k} is not a valid encoded public key`);
        }
        ss.emitPush(k);
    });
    ss.emitPush(keys.length);
    ss.emit(sc_1.OpCode.CHECKMULTISIG);
    return ss.str;
}
exports.constructMultiSigVerificationScript = constructMultiSigVerificationScript;
/**
 * Returns the list of public keys found in the verification script.
 * @param verificationScript Verification Script of an Account.
 */
function getPublicKeysFromVerificationScript(verificationScript) {
    const ss = new u_1.StringStream(verificationScript);
    const keys = [];
    while (!ss.isEmpty()) {
        const byte = ss.read();
        if (byte === "21") {
            keys.push(ss.read(33));
        }
    }
    return keys;
}
exports.getPublicKeysFromVerificationScript = getPublicKeysFromVerificationScript;
/**
 * Returns the number of signatures required for signing for a verification Script.
 * @param verificationScript Verification Script of a multi-sig Account.
 */
function getSigningThresholdFromVerificationScript(verificationScript) {
    const checkSigOpCode = verificationScript.slice(verificationScript.length - 2);
    if (checkSigOpCode === "ac") {
        return 1;
    }
    else if (checkSigOpCode === "ae") {
        const ss = new u_1.StringStream(verificationScript);
        const byte = parseInt(ss.peek(), 16);
        if (byte < 80) {
            const hexNum = u_1.reverseHex(ss.readVarBytes());
            return parseInt(hexNum, 16);
        }
        else {
            return parseInt(ss.read(), 16) - 80;
        }
    }
    else {
        throw new Error("VerificationScript does not call CHECKSIG or CHECKMULTISIG.");
    }
}
exports.getSigningThresholdFromVerificationScript = getSigningThresholdFromVerificationScript;
/**
 * Extract signatures from invocationScript
 * @param invocationScript InvocationScript of a Witness.
 */
function getSignaturesFromInvocationScript(invocationScript) {
    const ss = new u_1.StringStream(invocationScript);
    const sigs = [];
    while (!ss.isEmpty()) {
        const byte = parseInt(ss.peek(), 16);
        if (byte > 80) {
            continue;
        }
        else if (byte === 4 * 16) {
            sigs.push(ss.readVarBytes());
        }
    }
    return sigs;
}
exports.getSignaturesFromInvocationScript = getSignaturesFromInvocationScript;
