import { TableCell, TableRow, Typography } from '@material-ui/core'
import { TokenUtils } from 'carbon-js-sdk'
import { Market } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/market'
import { CellLink, OrderStatusLabel } from 'js/components'
import { shorten } from 'js/helpers/utils'
import { useRedux } from 'js/hooks'
import { switcheo } from 'js/theme/palettes/colors'
import { SimpleMap, bnOrZero } from 'js/utils'
import moment from 'moment'
import React, { ReactElement, useMemo } from 'react'

export interface LiquidationEngineRowProps {
  order: any;
  markets: SimpleMap<Market>
}

const LiquidationEngineRow: React.FunctionComponent<LiquidationEngineRowProps> = ({
  order,
  markets,
}: LiquidationEngineRowProps): ReactElement<LiquidationEngineRowProps> => {
  const {
    market: marketName,
    side,
    status,
    quantity,
    price,
    avgFilledPrice
  } = order
  const sdk = useRedux((state) => state.core.carbonSDK)
  const market = markets[marketName]
  const quantityBN = bnOrZero(quantity)
  const priceBN = bnOrZero(price)
  const lotDp = bnOrZero(market?.lotSize).dp() ?? 0
  const tickDp = bnOrZero(market?.tickSize).dp() ?? 0
  const symbolOverride = market?.marketType === 'futures' ? TokenUtils.FuturesDenomOverride : undefined
  const baseSymbol = sdk?.token.getTokenName(market?.base ?? '', symbolOverride) ?? ''
  const qouteSymbol = sdk?.token.getTokenName(market?.quote ?? '', symbolOverride) ?? ''
  const quantityShifted = sdk?.token.toHuman(market?.base, quantityBN)
  const priceShiftDp = market ? market?.basePrecision?.toNumber() - market?.quotePrecision?.toNumber() : 0
  const priceShifted = priceBN?.shiftedBy(priceShiftDp)
  const avgPriceShifted = bnOrZero(avgFilledPrice).shiftedBy(priceShiftDp)
  const positionSide = useMemo(() => side === 'buy' ? 'Short' : 'Long', [side])
  const insuranceFund =
    (positionSide === 'Long' ? bnOrZero(avgFilledPrice).minus(priceBN).shiftedBy(priceShiftDp).multipliedBy(quantityShifted ?? 0).toFormat(3)
      : priceBN.minus(avgFilledPrice).shiftedBy(priceShiftDp).multipliedBy(quantityShifted ?? 0).toFormat(3))
  return (
    <TableRow hover>
      <TableCell>
        <CellLink to={`/order/${order.orderId}`}>{shorten(order.orderId, 5)}</CellLink>
      </TableCell>
      <TableCell>
        <CellLink to={`/market/${encodeURIComponent(marketName)}`}>{shorten(market?.displayName, 10)}</CellLink>
      </TableCell>
      <TableCell>
        {positionSide}
        <br />
        <Typography variant='caption' style={{ color: status === 'filled' ? switcheo.green[400] : switcheo.red }}><OrderStatusLabel labelsOverride={{ Cancelled: 'Failed', Filled: 'Success' }}>{status}</OrderStatusLabel></Typography>
      </TableCell>
      <TableCell align="right">{quantityShifted?.toFormat(lotDp) ?? '-'} {baseSymbol}</TableCell>
      <TableCell align="right">{priceShifted?.toFormat(tickDp) ?? '-'}</TableCell>
      <TableCell align="right">{avgPriceShifted?.toFormat(tickDp) ?? '-'}</TableCell>
      <TableCell align="right">{quantityShifted?.multipliedBy(avgPriceShifted).toFormat(2) ?? '-'}  {qouteSymbol}</TableCell>
      <TableCell align="right" style={{ color: bnOrZero(insuranceFund).gt(0) ? switcheo.green[400] : switcheo.red }}>{insuranceFund} {qouteSymbol}</TableCell>
      <TableCell align="right">
        <CellLink to={`/block/${order.blockHeight}`}>
          {order.blockHeight} <br />
          <Typography variant="caption" style={{ fontWeight: 500, color: switcheo.text.grey }}>({moment(order.timestamp).format('lll')})</Typography>
        </CellLink>
      </TableCell>
    </TableRow >
  )
}

export default LiquidationEngineRow
