import { Box, makeStyles, TableRow, Theme, Typography } from '@material-ui/core'
import React, { ReactElement, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { useHistory } from 'react-router-dom'

import { TableCellInfo, TableSectionCell, TokenIcon } from 'js/components'
import { TextComponent } from 'js/components/Text'
import { useRedux } from 'js/hooks'
import { formatPoolTokenNameAndWeights, TokenWithSupply } from '../utils'

interface Props {
  model: TokenWithSupply,
}

const PoolTokenInfoRow: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { model } = props
  const classes = useStyles()
  const history = useHistory()
  const tokens = useRedux((state) => state.app.tokensMap)

  const handleClick = () => {
    history.push(`/token/${encodeURIComponent(model?.denom)}`)
  }

  const [name, weights] = useMemo(() => {
    return formatPoolTokenNameAndWeights(model, tokens)
  }, [tokens, model])

  const TokenInfoValues: TableCellInfo[] = [
    {
      align: 'left',
      content: (
        <Box display="flex" alignItems="center">
          <TokenIcon denom={model.denom} />
          <Box className={classes.singlelineText} ml={1.5}>
            <TextComponent
              svgIcon={undefined}
              showIcon={false}
              className={classes.tokenSymbol}
            >
              <strong>{name} ({model.symbol})</strong>
            </TextComponent>
            <Typography variant="body2" className={classes.denomText}>{model.denom}</Typography>
          </Box>
        </Box>
      ),
    },
    {
      align: 'right',
      content: (
        <Box paddingRight={1}>
          {weights}
        </Box>
      ),
    },
    {
      align: 'right',
      content: (
        <Box paddingRight={1}>
          {new BigNumber(model?.totalSupply).toFormat()}
        </Box>
      ),
    },
  ]

  return (
    <TableRow key={model?.denom} hover={true} className={classes.row} onClick={handleClick}>
      {TokenInfoValues.map((info: TableCellInfo, index: number) => (
        <TableSectionCell className={classes.cellClass} {...info} key={index} />
      ))}
    </TableRow>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    cursor: 'pointer',
  },
  blockChainImg: {
    marginRight: theme.spacing(1),
    width: '1.125rem',
    height: '1.125rem',
  },
  cellClass: {
    padding: '0.5rem 1.15rem 0.5rem 0.7rem',
  },
  singlelineText: {
    width: 300,
    '& p': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  denomText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    color: theme.palette.grey[600],
  },
  tokenSymbol: {
    ...theme.typography.body1,
  },
}))

export default PoolTokenInfoRow
