import axios from 'axios'
import { Page } from 'js/components'
import { useAsyncTask, useRedux } from 'js/hooks'
import { ReactElement, useEffect, useState } from 'react'
import Positions from './components/Positions'
import OpenInterestChart from './components/OpenInterestChart'
import MarketFilter from './components/MarketFilter'
import { Box, makeStyles, Theme } from '@material-ui/core'

interface Props { }

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (): ReactElement<Props> => {
  const classes = useStyles()
  const sdk = useRedux((state) => state.core.carbonSDK)
  const allMarkets = useRedux((state) => state.app.markets)
  const [closedPositions, setClosedPositions] = useState([])
  const [openPositions, setOpenPositions] = useState([])
  const [selectedMarket, setSelectedMarket] = useState<string[]>([])
  const [getClosedPositions] = useAsyncTask('getClosedPositions')
  const [getOpenPositions] = useAsyncTask('getOpenPositions')

  useEffect(() => {
    if (!sdk) return
    const urlPrefix = sdk.network === 'testnet' ? 'test-' : sdk.network === 'mainnet' ? '' : 'dev-'

    getClosedPositions(async () => {
      const closedPositionsResp = await axios.get(`https://${urlPrefix}api-insights.carbon.network/position/view/latest?${selectedMarket ? `market=${selectedMarket}&` : ''}limit=1000&offset=0&sort=DESC&status=closed`)
      setClosedPositions(closedPositionsResp.data.result.entries)
    })

    getOpenPositions(async () => {
      const openPositionsResp = await axios.get(`https://${urlPrefix}api-insights.carbon.network/position/view/latest?${selectedMarket ? `market=${selectedMarket}&` : ''}limit=1000&offset=0&sort=DESC`)
      setOpenPositions(openPositionsResp.data.result.entries)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk, selectedMarket])

  const onMarketSelect = (market: string[]) => {
    setSelectedMarket(market)
  }
  const filters = Object.values(allMarkets).filter((market) => market.isActive && market.marketType !== 'spot').map((market) => {
    const result = { label: market.displayName, value: market.id, selected: false }
    return result
  })
  //push label 'All' to first item on array
  filters.unshift({ label: 'All Markets', value: '', selected: selectedMarket.length === 0 })

  return (
    <Page title="Positions"
      rightMenu={
        <Box className={classes.rightMenuWrapper}>
          <MarketFilter
            options={filters}
            onFilter={onMarketSelect}
          />
        </Box>
      }>
      <OpenInterestChart market={selectedMarket} />
      <Positions view="open" positions={openPositions} title="Open Positions" tasks={['getOpenPositions']} action={null} />
      <Positions view="closed" positions={closedPositions} title="Closed Positions" tasks={['getClosedPositions']} action={null} />
    </Page>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  rightMenuWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
}))
