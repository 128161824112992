"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SimpleParser = exports.Fixed8Parser = exports.StringParser = exports.IntegerParser = exports.NoOpParser = exports.buildParser = void 0;
const u_1 = require("../u");
/**
 * Builds a parser to parse the results of the stack.
 * @param args A list of functions to parse arguments. Each function is mapped to its corresponding StackItem in the result.
 * @returns parser function
 */
function buildParser(...args) {
    return (result) => {
        if (result.stack.length !== args.length) {
            throw new Error(`Wrong number of items to parse! Expected ${args.length} but got ${result.stack.length}!`);
        }
        return result.stack.map((item, i) => args[i](item));
    };
}
exports.buildParser = buildParser;
/**
 * This just returns the value of the StackItem.
 */
function NoOpParser(item) {
    return item.value;
}
exports.NoOpParser = NoOpParser;
/**
 * Parses the result to an integer.
 */
function IntegerParser(item) {
    return parseInt(item.value || "0", 10);
}
exports.IntegerParser = IntegerParser;
/**
 *  Parses the result to a ASCII string.
 */
function StringParser(item) {
    return u_1.hexstring2str(item.value);
}
exports.StringParser = StringParser;
/**
 * Parses the result to a Fixed8.
 */
function Fixed8Parser(item) {
    return u_1.Fixed8.fromReverseHex(item.value);
}
exports.Fixed8Parser = Fixed8Parser;
/**
 * Parses the VM Stack and returns human readable strings. The types are inferred based on the StackItem type.
 * @param res RPC Response
 * @return Array of results
 */
function SimpleParser(res) {
    return res.stack.map((item) => {
        switch (item.type) {
            case "ByteArray":
                return StringParser(item);
            case "Integer":
                return IntegerParser(item);
            default:
                throw Error(`Unknown type: ${item.type}`);
        }
    });
}
exports.SimpleParser = SimpleParser;
