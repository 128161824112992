import { Accordion, AccordionDetails, AccordionSummary, Grow, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Skeleton } from '@material-ui/lab';
import { animated, useSpring } from '@react-spring/web';
import clsx from 'clsx';
import { CellLink } from 'js/components';
import Page from 'js/components/Page';
import TableSearchBar from 'js/components/TableSearchBar';
import { useAsyncTask, useRedux } from 'js/hooks';
import Long from 'long';
import React, { ReactElement, Suspense, useEffect, useState } from 'react';
import { AccordionWithColumnLoading } from './components/AccordionWithColumnLoading';
import { Column } from './components/Column';
import { Oracle } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/oracle/oracle';
import { TokenPrice } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/pricing/pricing';
import { Market } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/market';

const AccordionDetailsContent = React.lazy(() => import('./components/AccordionDetailsContent'))

interface Props { }

const OracleList: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const classes = useStyles()
  const sdk = useRedux((state) => state.core.carbonSDK)
  // const tokens = useRedux((state) => state.app.tokensMap)
  const [expanded, setExpanded] = useState<any>({})
  const [allOraclesInfo, setAllOraclesInfo] = useState<Oracle[] | undefined>(undefined)
  const [oracleVotes, setOracleVotes] = useState<any[]>([])
  const [oraclePricing, setOraclePricing] = useState<TokenPrice[]>([])
  const [allMarkets, setAllMarkets] = useState<Market[]>([])
  const [search, setSearch] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [getAllOracles] = useAsyncTask('getAllOracles')
  const [getOracleVote] = useAsyncTask("getOracleVote")
  const [getOraclePricing] = useAsyncTask("getOraclePricing")
  const [getAllMarkets] = useAsyncTask("getAllMarkets")

  // const colors = switcheo.chart // colors Array

  const getOracleVotingInfo = async () => {
    try {
      let oracleVotesWindowResult: any[] = []
      const oracleVotesWindow = await sdk?.query?.oracle?.OracleVotesWindowAll({
        pagination: {
          limit: new Long(10000),
          offset: Long.UZERO,
          key: new Uint8Array(),
          countTotal: false,
          reverse: false,
        }
      })
      oracleVotesWindowResult = oracleVotesWindowResult.concat(oracleVotesWindow?.oracleVotesWindows ?? [])
      if (oracleVotesWindow?.pagination?.nextKey) {
        //TODO: change to recursive
        const oracleVotesWindowNextPage = await sdk?.query?.oracle?.OracleVotesWindowAll({
          pagination: {
            limit: new Long(10000),
            offset: Long.UZERO,
            key: oracleVotesWindow?.pagination?.nextKey ?? new Uint8Array(),
            countTotal: false,
            reverse: false,
          }
        })
        oracleVotesWindowResult = oracleVotesWindowResult.concat(oracleVotesWindowNextPage?.oracleVotesWindows ?? [])
      }

      const oraclesIdsInfo = await sdk?.query?.oracle?.OracleAll({
        pagination: {
          limit: new Long(10000),
          offset: Long.UZERO,
          key: new Uint8Array(),
          countTotal: false,
          reverse: false,
        }
      })

      let allVotes: any = {}
      oraclesIdsInfo?.oracles.forEach((oraclesId) => {
        if (!allVotes[oraclesId.id]) allVotes[oraclesId.id] = 0
      })
      for (let oracleVote of oracleVotesWindowResult ?? []) {
        const { oracleId, voteCount } = oracleVote
        allVotes[oracleId] += Number(voteCount)
      }
      setOracleVotes(allVotes)
    }
    catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (!sdk) return
    let loadingOracleInfo = false
    let interval: any
    getOracleVote(async () => {
      if (!oracleVotes?.length) {
        getOracleVotingInfo() //initialise
      }
      interval = setInterval(async () => {
        if (loadingOracleInfo) return
        loadingOracleInfo = true
        await getOracleVotingInfo()
        loadingOracleInfo = false
      }, 10000)
    })
    return () => clearInterval(interval)
  }, [sdk]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!sdk) return
    getAllOracles(async () => {
      setLoading(true)
      const allOraclesInfoResp = await sdk?.query.oracle.OracleAll({
        pagination: {
          limit: new Long(10000),
          offset: Long.UZERO,
          key: new Uint8Array(),
          countTotal: false,
          reverse: false,
        }
      })
      setAllOraclesInfo(allOraclesInfoResp.oracles)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk])

  useEffect(() => {
    if (!sdk) return
    getAllMarkets(async () => {
      const allMarkets = await sdk.query.market.MarketAll({
        pagination: {
          limit: new Long(10000),
          offset: Long.UZERO,
          key: new Uint8Array(),
          countTotal: false,
          reverse: false,
        }
      })
      setAllMarkets(allMarkets?.markets ?? [])
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk])

  useEffect(() => {
    getOraclePricing(async () => {
      const oraclePricing = await sdk?.query.pricing.TokenPriceAll({
        pagination: {
          limit: new Long(10000),
          offset: Long.UZERO,
          key: new Uint8Array(),
          countTotal: false,
          reverse: false,
        }
      })
      setOraclePricing(oraclePricing?.tokenPrices ?? [])
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk])

  const handleChange = (panel: string) => {
    setExpanded((prev: any) => {
      return { ...prev, [panel]: !prev[panel] }
    });
  }

  const setSearchInput = (input: string, clear: boolean = false) => {
    if (clear && search === input) setSearch('')
    else setSearch(input)
  }

  const allOracleIds = Object.keys(oracleVotes).filter((o) => o !== '.CSTRIDE')

  const placeHolderData = new Array(32).fill('0')
  // return (
  //   <TokenOraclePriceChart data={{}} />
  // )
  return (
    <Page title="Oracles" id="oraclesPage">
      <div>
        <OracleWindow />
        <div className={classes.healthWrapper}>
          {
            loading && placeHolderData.map((_, index) => {
              return (
                <div className={classes.skeleton} key={index}>
                  <Skeleton variant="rect" height={40} />
                </div>
              )
            })
          }
          {allOracleIds.map((oracleId: any, index: number) => {
            const votes: number = oracleVotes[oracleId]
            const isRed = votes < 1
            const isOrange = false

            const numOfColumns = 8
            // const row = Math.floor(index / numOfColumns) + 1
            const isOuter = index < numOfColumns || index % numOfColumns === 0 || index > (allOracleIds.length - 1 - numOfColumns) || (index + 1) % (numOfColumns) === 0
            const is2ndOuterLayer = (index - 1) < numOfColumns || (index - 1) % numOfColumns === 0 || (index - 1) > (allOracleIds.length - 1 - numOfColumns) || (index) % (numOfColumns) === 0
            const is3rdOuterLayer = (index - 2) < numOfColumns || (index - 2) % numOfColumns === 0 || (index - 2) > (allOracleIds.length - 2 - numOfColumns) || (index - 1) % (numOfColumns) === 0
            const animationTimeout = isOuter ? 1300 : is2ndOuterLayer ? 1000 : is3rdOuterLayer ? 700 : 300
            const isSelected = search && oracleId.toLowerCase().includes(search.toLowerCase())
            const isUnselected = !isSelected && search

            return <Grow in={true} timeout={animationTimeout} key={oracleId}>
              <div>
                <div className={clsx(classes.health, 'green', { orange: isOrange, red: isRed, selected: isSelected, notSelected: isUnselected })} key={oracleId} onClick={() => setSearchInput(oracleId, true)}>
                  <div>
                    <Typography className={classes.oracleId}>
                      {oracleId}
                    </Typography>
                    <Typography>
                      <strong>
                        <AnimatedNumberText input={votes} key={oracleId} />
                      </strong>
                    </Typography>
                  </div>
                </div>
              </div>
            </Grow>
          })}
        </div>
      </div>
      <div style={{ minHeight: '20rem', margin: '2rem 0rem', display: 'grid', gap: '1em', alignContent: "flex-start" }}>
        <div className={classes.searchBarContainer}>
          <TableSearchBar onSearch={setSearchInput} placeholder={'Eg. CSWTH'} forceInput={search ?? ''} />
        </div>
        {
          !loading && allOraclesInfo && allOraclesInfo?.filter((o) => o.id.toLowerCase().includes(search.toLowerCase())).map((oracle, index) => {
            const { id, creator, description, maxResultAge, minTurnoutPercentage, resolution, resultStrategy, spec } = oracle!
            const aniTimeout = allOracleIds.length ? 500 : Math.min(Math.max(Math.max(1, index) * 700, 800), 2100)
            const denom = oraclePricing.find((o) => o.oracleId === id)?.denom ?? allMarkets?.find((mk) => mk.indexOracleId === id)?.base
            return (
              <Grow in={!loading} timeout={aniTimeout} key={id.toString() + creator}>
                <Accordion key={id.toString() + creator} id={id.toString() + creator} expanded={expanded[id.toString()] ?? false} onChange={(e) => handleChange(id.toString())} TransitionProps={{ unmountOnExit: true }}>
                  <AccordionSummary
                    className={classes.accordionSummary}
                    expandIcon={<ExpandMoreIcon />}
                    id="panel1bh-header"
                  >
                    <div className={classes.columnContainer}>
                      <Column title={"ID"} text={id.toString()} loading={loading} />
                      <Column title={"Creator"} text={creator} copy truncate link={`/account/${creator}`} loading={loading} />
                      <Column title={"Description"} text={description} loading={loading} />
                      <Column title={"Max Result Age"} text={maxResultAge.toString(10)} loading={loading} unit={"s"} />
                      <Column title={"Min Turnout %"} text={minTurnoutPercentage.toString(10)} loading={loading} unit={"%"} />
                      <Column title={"Resolution"} text={resolution.toString(10)} loading={loading} unit={"s"} />
                      <Column title={"Result Strategy"} text={resultStrategy} loading={loading} />
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <Suspense fallback={<div style={{ width: '100%' }}><LinearProgress /></div>}>
                      <AccordionDetailsContent spec={spec} denom={denom} id={id} />
                    </Suspense>
                  </AccordionDetails>
                </Accordion>

              </Grow>
            )
          })
        }
        { // loading display component
          loading && <div className={classes.loading}>
            <AccordionWithColumnLoading />
          </div>
        }
      </div>
    </Page>
  )
}

const OracleWindow = () => {
  const classes = useStyles()
  const latestBlock = useRedux((state) => state.app.latestBlock)
  const sdk = useRedux((state) => state.core.carbonSDK)
  const [getOracleWindow] = useAsyncTask("getOracleWindow")
  const [oracleWindow, setOracleWindow] = useState<number>(0)
  const currentBlockHeight = latestBlock?.height ?? 0
  const currentBlockInWindow = (currentBlockHeight % oracleWindow) ?? 0
  const oracleWindowStartBlock = currentBlockHeight - currentBlockInWindow
  const oracleWindowEndBlock = currentBlockHeight + (oracleWindow - currentBlockInWindow)

  useEffect(() => {
    if (!sdk) return
    getOracleWindow(async () => {
      const oracleWindow = await sdk.query.params.Params({ subspace: 'oracle', key: 'OracleSlashWindowBlock' })
      setOracleWindow(Number(oracleWindow?.param?.value ?? 0))
    })
  }, [sdk]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.title}>
      <Typography variant="h2">Oracles Vote</Typography>
      <Typography variant="h4">
        Current Block Window: <CellLink to={`/block/${oracleWindowStartBlock}`}>{oracleWindowStartBlock.toString()}</CellLink> ~ <CellLink to={`/block/${oracleWindowEndBlock}`}>{oracleWindowEndBlock.toString()}</CellLink>
      </Typography>
    </div>
  )
}

const AnimatedNumberText = (props: any, key: string) => {
  const { input } = props
  const springValue = useSpring({
    from: { val: Number(0) },
    to: { val: Number(input) },
    config: {
      velocity: 0.02,
      friction: 10,
      mass: 0.8
    },
    immediate: Number(input) === 0
  });
  return (
    <animated.span id={`${key}`} key={`${key}`}>{springValue.val.to((value) => {
      return value.toLocaleString(undefined, { maximumFractionDigits: 0 })
    })}</animated.span>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  skeleton: {
    margin: '1px',
    minWidth: '6em'
  },
  title: {
    display: 'flex', alignItems: 'end',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      display: 'grid'
    },
  },
  searchBarContainer: {
    marginLeft: 'auto',
    width: 'max-content',
    [theme.breakpoints.down('md')]: {
      margin: 'auto',
      width: '100%',
    },
  },
  oracleId: {
    fontSize: '0.65rem',
  },
  health: {
    cursor: 'pointer',
    color: 'white',
    boxSizing: 'border-box',
    padding: '0.1rem 0rem',
    border: '0.5px solid white',
    textAlign: 'center',
    backgroundColor: 'grey',
    height: '100%',
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
    minWidth: '5.7em',
    filter: 'contrast(100%)',
    '&.green': {
      backgroundColor: '#38C156'
    },
    '&.orange': {
      backgroundColor: '#D78100'
    },
    '&.red': {
      backgroundColor: '#E84B55'
    },
    '&:hover': {
      position: 'relative',
      transform: 'scale(1.2)',
      boxShadow: '1px 1px 6px 2px #f5fafa',
      transition: 'all ease-in-out 0.1s',
      zIndex: 999,
    },
    '&.selected': {
      position: 'relative',
      transform: 'scale(1)',
      // boxShadow: '1px 2px 8px 2px rgb(15,33,79)',
      transition: 'all ease-in-out 0.5s'
    },
    '&.notSelected': {
      filter: 'contrast(20%)',
      // boxShadow: '1px 2px 8px 2px rgb(15,33,79)',
      transition: 'all ease-in-out 0.5s'
    }
  },
  healthWrapper: {
    marginTop: '0.5em',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '10em',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(7, minmax(0.5em, auto))',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(4, minmax(0.5em, auto))',
    },
  },
  accordionDetails: {
    backgroundColor: '#E5EFF2', minHeight: '10em', display: 'block',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  loading: {
    // display: 'flex',
    // placeContent: 'center',
    // placeItems: 'center',
    // minHeight: '20rem'
  },
  columnContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1.5fr 4fr 1fr 1fr 1fr 1fr',
    width: '100%',
    gap: '1.5em',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, minmax(0.5em, auto))',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'auto',
      gap: '0.5em'
    }
  },
  accordionSummary: {
    [theme.breakpoints.down('md')]: {
      flexFlow: 'column',
      '& > .MuiAccordionSummary-content': {
        width: '100%'
      },
    }
  }
}))

export default OracleList
