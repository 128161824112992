import { Token } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import { Record, RecordOf } from 'immutable'
import { TokenBalance, TokensAction, TokensActionType } from '../actions/tokens'

export type TokensState = RecordOf<TokensProps>
export interface TokensProps {
  balances: { [key: string]: TokenBalance }
  info: Token[]
}

const makeState: Record.Factory<TokensProps> = Record<TokensProps>({
  balances: {},
  info: [],
})

function tokensReducer(
  state: TokensState = makeState(),
  action: TokensAction,
): TokensState {
  switch (action.type) {
    case TokensActionType.CLEAR:
      return makeState()
    case TokensActionType.SET_TOKENS_BALANCES:
      return state.updateIn(['balances'], () => action.balances)
    case TokensActionType.SET_TOKENS_INFO:
      return state.updateIn(['info'], () => action.info)
    default:
      return state
  }
}

export default tokensReducer
