import { Box, Typography } from '@material-ui/core'
import { CellLink, DataLoadSegment, NotFoundState, Page } from 'js/components'
import { Paths, TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import React, { Fragment, ReactElement, useMemo } from 'react'
import Information from './Information'
import RichList from './Richlist'
import { TokenClient } from 'carbon-js-sdk/lib/clients'
import { formatPoolTokenNameAndWeights } from '../utils'
// import Information from './Information'
// import Richlist from './Richlist'
// import TokenOraclePriceChart from './TokenOraclePriceChart'
// import { updateDateFiltersOraclesPriceChart } from 'js/actions/token'

interface Props { }

const TokenComponent: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const { token, rich } = useRedux((state) => state.token)
  const tokens = useRedux((state) => state.app.tokensMap)
  const [tokenDetailsLoading] = useTaskSubscriber(TaskNames.Token.Detail, TaskNames.Tokens.Info)
  // const denomsWithOracleData = useRedux((state) => state.app.allOracleTokenPrices).map(tokenPrice => tokenPrice.denom)
  // const { oraclesPriceChartDataResponse } = useRedux((state) => state.token)
  // const tokenHasOracleData = denomsWithOracleData.includes(token?.denom ?? '')

  const formattedTokenName = useMemo(() => {
    if (!token) return ''
    if (TokenClient.isPoolToken(token.denom) || token.denom.startsWith('cplt/'))  {
      return formatPoolTokenNameAndWeights(token, tokens)[0]
    }
    return token.name
  }, [token, tokens])

  const breadcrumbs = token ? [{ href: '/tokens', text: 'Tokens'}, { text: formattedTokenName }] : undefined

  return (
    <Page title="Token Details" breadcrumbs={breadcrumbs}>
      <DataLoadSegment loading={tokenDetailsLoading}>
        {
          token ?
          <Fragment>
            {/* {tokenHasOracleData && (<ChartContainer
              taskNames={[TaskNames.Token.PricesChart]}
              title={
                  <Typography variant={"h3"} component={"span"}>
                    {`Token Price`}
                  </Typography>
              }
              date={true}
              action={updateDateFiltersOraclesPriceChart}
            >
              <TokenOraclePriceChart data={oraclesPriceChartDataResponse} />
            </ChartContainer>)} */}
            <Box mb={2}>
              <Information token={token!} holderCount={rich?.length} formattedTokenName={formattedTokenName} />
            </Box>
            <RichList rich={rich} />
          </Fragment>
          :
          <NotFoundState title="No Token Found">
            <Typography variant="body1">
              We can’t find any token with this ID. Please check your network setting or go back to the&nbsp;
              <CellLink to={Paths.Tokens}>
                Tokens
              </CellLink>
              &nbsp;page to view existing tokens.
            </Typography>
          </NotFoundState>
        }
      </DataLoadSegment>
    </Page>
  )
}

export default TokenComponent
