import { Box, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { QueryGetLiquidationAndADLResponse } from 'carbon-js-sdk/lib/insights'
import { DataLoadSegment, ListTable, PaginationByOffset, Section, TableEmptyState } from 'js/components'
import { SimpleMap } from 'js/utils'
import React, { ReactElement } from 'react'
import LiquidationADLRow from './LiquidationADLRow'
import { Market } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/market'

export interface LiquidationADLsProps {
  filterStatus: boolean
  orders?: QueryGetLiquidationAndADLResponse
  title: string
  action: any
  markets: SimpleMap<Market>
  loadName: string[]
  loadCondition?: boolean
  sectionClass?: string
}

const LiquidationADLs: React.FunctionComponent<LiquidationADLsProps> = ({
  filterStatus = false,
  orders,
  title,
  action,
  markets,
  loadName,
  loadCondition = true,
  sectionClass,
}: LiquidationADLsProps): ReactElement<LiquidationADLsProps> => {
  // const [loading] = useTaskSubscriber(...loadName)
  return (
    <Section className={sectionClass} title={title}>
      <DataLoadSegment
        filter={filterStatus}
        loading={!loadCondition}
      >
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Order ID</TableCell>
              <TableCell>Account</TableCell>
              <TableCell>Market</TableCell>
              {/* <TableCell>Initiator</TableCell> */}
              <TableCell>Event</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Liquidated Value</TableCell>
              <TableCell align="right">Created At</TableCell>
              {/* <TableCell align="right"></TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders?.entries.map((order) => (
              <LiquidationADLRow key={order.id} order={order} markets={markets} />
            ))}
          </TableBody>
        </ListTable>
        {!!orders?.entries.length && (
          <Box marginTop={3}>
            <PaginationByOffset
              response={orders}
              action={action}
            // filters={{}}
            // useBlockHeight={false}
            />
          </Box>
        )}
        {!orders?.entries.length && (
          <TableEmptyState itemName={title.toLowerCase()} />
        )}
      </DataLoadSegment>
    </Section>
  )
}

export default LiquidationADLs
