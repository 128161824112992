import { BigNumber } from 'bignumber.js'
import { ExtendedPool, QueryAllPoolResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/liquiditypool/query'
import { bnOrZero } from 'carbon-js-sdk/lib/util/number'
import { DataLoadSegment, Page } from 'js/components'
import { Paths } from 'js/constants'
import { useAsyncTask, useRedux } from 'js/hooks'
import { BIG_ZERO } from 'js/utils'
import Long from 'long'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import PoolDetailsComponents from "./PoolDetailsComponents"

interface Props { }

const IndividualPool: React.FunctionComponent<Props> = (): ReactElement<Props> => {
    const { pools, rewards } = useRedux((state) => state.pools)
    const { poolId } = useParams()
    let total = BIG_ZERO
    const sdk = useRedux((state) => state.core.carbonSDK)
    const [getAllPools] = useAsyncTask('getAllPools')
    const [get24HrPoolsVolume] = useAsyncTask('get24HrPoolsVolume')
    const [getWeeklyRewards] = useAsyncTask('getWeeklyRewards')
    const [allPools, setLiquidityPools] = useState(pools);
    const [allRewards, setRewards] = useState(rewards);
    allPools.forEach((p: ExtendedPool) =>
        total = total.plus(new BigNumber(p.rewardsWeight)),
    )
    const [poolVolume, set24HrPoolsVolume] = useState(new BigNumber(0));
    const dispatch = useDispatch();
    useEffect(() => {
        if (!sdk) return
        getAllPools(async () => {
            try {
                if (allPools.length !== 0) {
                    return;
                }
                const lpQueryClient = sdk.query.liquiditypool
                const LiquidityPoolResponse = await lpQueryClient.PoolAll({
                    pagination: {
                        limit: new Long(10000),
                        offset: Long.UZERO,
                        key: new Uint8Array(),
                        countTotal: false,
                        reverse: false,
                    }
                }) as QueryAllPoolResponse
                setLiquidityPools(LiquidityPoolResponse.pools);
                dispatch(setLiquidityPools(LiquidityPoolResponse.pools));
            }
            catch (err) {
                console.error(err)
            }
        })
        get24HrPoolsVolume(async () => {
            try {
                //Get Unix time of now and 24hour before
                const from = moment().subtract(1, "day").unix().toString()
                const until = moment().unix().toString()
                const InsightsQueryClient = sdk.insights
                const poolVolumeResponse = await InsightsQueryClient.PoolVolume({
                    from,
                    until,
                    interval: 'hour',
                    poolId: poolId
                })
                var volumeResult = new BigNumber(0);
                (poolVolumeResponse.result).entries.forEach(
                    (poolVolume: any) => {
                        volumeResult = volumeResult.plus(bnOrZero(poolVolume.volumeValue))
                    }
                )
                set24HrPoolsVolume(volumeResult);
            }
            catch (err) {
                console.error(err)
            }
        })
        getWeeklyRewards(async () => {
            try {
                if (!allRewards.isZero()) {
                    return;
                }
                const rewardsQueryClient = sdk.lp
                const weeklyRewards = await rewardsQueryClient.getWeeklyRewards() as BigNumber
                setRewards(weeklyRewards)
                dispatch(setRewards(weeklyRewards));
            }
            catch (err) {
                console.error(err)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sdk])
    return (
        <Page
            backLink={Paths.Pools}
            backLabel="All Pools"
            title="Pool Details" >
            <DataLoadSegment loading={allPools.length === 0}>
                {allPools.length > 0 ?
                    <PoolDetailsComponents
                        pool={allPools[poolId - 1]}
                        total={total}
                        rewards={allRewards}
                        volume={poolVolume} /> : undefined}
            </DataLoadSegment>
        </Page>
    )
}

export default IndividualPool
