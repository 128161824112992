import { Token, TokenBalance as TB } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import { Action } from 'redux'

export interface TokenBalance extends TB {
  total: string
}

type TokenBalances = { [key: string]: TokenBalance }

export enum TokensActionType {
  CLEAR = 'CLEAR_TOKENS',
  SET_TOKENS_BALANCES = 'SET_TOKENS_BALANCES',
  SET_TOKENS_INFO = 'SET_TOKENS_INFO',
}

export type TokensAction =
  | ClearAction
  | SetTokenBalancesAction
  | SetTokensInfoAction

export interface ClearAction extends Action<TokensActionType.CLEAR> { }
export function clear(): ClearAction {
  return {
    type: TokensActionType.CLEAR,
  }
}

export interface SetTokenBalancesAction
  extends Action<TokensActionType.SET_TOKENS_BALANCES> {
    balances: TokenBalances
}

export function setTotalBalances(balances: TokenBalances): SetTokenBalancesAction {
  return {
    type: TokensActionType.SET_TOKENS_BALANCES,
    balances,
  }
}

export interface SetTokensInfoAction
  extends Action<TokensActionType.SET_TOKENS_INFO> {
  info: Token[]
}

export function setTokensInfo(info: Token[]): SetTokensInfoAction {
  return {
    type: TokensActionType.SET_TOKENS_INFO,
    info,
  }
}
