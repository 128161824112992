import { useEffect, useState } from "react";

const Chatbox: React.FunctionComponent = () => {
  const [iframeUrl, setIframeUrl] = useState("");

  useEffect(() => {
    const randomNum = Math.floor(Math.random() * 1000);
    const iframefetching = async () => {
      const resultraw = await fetch("https://api-insights.carbon.network/info/alert", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: `tester${randomNum}`,
          email: `test${randomNum}@gmail.com`
        }),
      })
      const url = await resultraw.json();
      setIframeUrl(url?.result?.result);
    }
    iframefetching()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <main style={{ display: 'relative', textAlign: 'center', padding: '1em' }}>
      <h1>Insights AI Agent</h1>
      <iframe
        title="Insights Agent AI"
        className="mx-auto"
        style={{
          height: '70dvh',
          width: '70%',
          // position: 'absolute',
          // top: '50%',
          // left: '50%',
          // transform: 'translate(-50%, -50%)'
        }}
        src={iframeUrl}
      ></iframe>
    </main>
  );
}

export default Chatbox;