import { updateLeAccOrdersFilters, updateOrdersFilters } from 'js/actions/events'
import Page from 'js/components/Page'
import { TaskNames } from 'js/constants'
import { useRedux } from 'js/hooks'
import React, { ReactElement } from 'react'
import { LiquidationADLs, LiquidationEngine } from './components'
import InsuranceFundChart from 'js/views/Home/components/InsuranceFundChart'
interface Props {
}
// tslint:disable:prettier
const LiquidationAndADL: React.FunctionComponent<Props> = (): ReactElement<Props> => {
    const { le, leAccOrders } = useRedux((state) => state.events)
    const markets = useRedux((state) => state.app.markets)
    return (
        <Page title="Liquidation History">
            <LiquidationADLs
                filterStatus
                title="Liquidations &amp; ADL"
                orders={le}
                action={updateOrdersFilters}
                markets={markets}
                loadName={[
                    TaskNames.Events.LEOrders,
                    TaskNames.Events.LEOrderFilter,
                ]}
                loadCondition={
                    le?.entries?.length !== undefined &&
                    le?.entries?.length !== null
                }
            />
            <InsuranceFundChart />
            <LiquidationEngine
                filterStatus
                title="Liquidation Engine"
                orders={leAccOrders}
                action={updateLeAccOrdersFilters}
                markets={markets}
                loadName={[TaskNames.Events.LETrades]}
                loadCondition={
                    leAccOrders?.entries?.length !== undefined &&
                    leAccOrders?.entries?.length !== null}
            />
        </Page>
    )
}

export default LiquidationAndADL
