import BigNumber from 'bignumber.js'
import { CarbonSDK } from "carbon-js-sdk"
import { IndexedTx } from "@cosmjs/stargate"
import { Sha256, Ripemd160 } from "@cosmjs/crypto";
import { toBech32 } from "@cosmjs/encoding";
import { PubKey as Secp256k1PubKey } from "cosmjs-types/cosmos/crypto/secp256k1/keys";
import { PubKey as Ed25519PubKey } from "cosmjs-types/cosmos/crypto/ed25519/keys";
// import { LegacyAminoPubKey } from "cosmjs-types/cosmos/crypto/multisig/keys";
import { Any } from 'carbon-js-sdk/lib/codec/google/protobuf/any';
import { SignerInfo } from 'carbon-js-sdk/lib/codec/cosmos/tx/v1beta1/tx';

export const getTxFee = (sdk: CarbonSDK, transaction: IndexedTx) => {
  const feeDeductionEvent = transaction.events.find((event) => event.type === 'Switcheo.carbon.fee.FeeDeductionEvent')
  const feeDenom = feeDeductionEvent?.attributes.find((attr) => attr.key === 'denom')?.value
  const feeAmount = feeDeductionEvent?.attributes.find((attr) => attr.key === 'fee_amount')?.value

  let fee = 'unable to parse fee'
  let feeSymbol = ''
  if (sdk && feeDenom && feeAmount) {
    const feeAmountBN = new BigNumber(JSON.parse(feeAmount))

    if (feeAmountBN.isFinite()) {
      fee = sdk.token.toHuman(JSON.parse(feeDenom), feeAmountBN).toString()
      feeSymbol = sdk.token.getSymbol(JSON.parse(feeDenom))
    }
  }

  return [fee, feeSymbol]
}

// Function to derive address from a public key based on typeUrl
export  function getAddressFromSignerInfo(signerInfo: SignerInfo, prefix: string): string {
  if (!signerInfo.publicKey) {
    throw new Error("No public key provided in SignerInfo");
  }

  const pubKeyAny: Any = signerInfo.publicKey;
  const typeUrl = pubKeyAny.typeUrl;
  const value = pubKeyAny.value; // Uint8Array

  switch (typeUrl) {
    case "/cosmos.crypto.secp256k1.PubKey": {
      // Decode secp256k1 public key (35 bytes: 0x0a 0x21 + 33-byte key)
      const pubKey = Secp256k1PubKey.decode(value);
      const keyBytes = pubKey.key; // 33-byte compressed key

      // SHA-256 then RIPEMD-160
      const sha256Hash = new Sha256(keyBytes).digest();
      const ripemd160Hash = new Ripemd160(sha256Hash).digest();
      return toBech32(prefix, ripemd160Hash);
    }

    case "/cosmos.crypto.ed25519.PubKey": {
      // Decode ed25519 public key (34 bytes: 0x0a 0x20 + 32-byte key)
      const pubKey = Ed25519PubKey.decode(value);
      const keyBytes = pubKey.key; // 32-byte key

      // For ed25519, address is first 20 bytes of SHA-256 hash
      const sha256Hash = new Sha256(keyBytes).digest();
      const addressBytes = sha256Hash.slice(0, 20); // Truncate to 20 bytes
      return toBech32(prefix, addressBytes);
    }

    case "/cosmos.crypto.multisig.LegacyAminoPubKey": {
      // Decode multisig public key
      // const multisigPubKey = LegacyAminoPubKey.decode(value);
      // multisigPubKey.threshold: number of signatures required
      // multisigPubKey.publicKeys: Any[] of nested public keys

      // Multisig doesn't have a single address; it’s a combination of keys
      // Typically, the address is derived from the Amino-encoded multisig key
      // or precomputed and stored on-chain. We can't derive it directly here
      // without additional context (e.g., Amino encoding logic).
      throw new Error(
        "Multisig public keys do not directly map to a single address. " +
        "Address derivation requires chain-specific logic or precomputed data."
      );
    }

    default:
      throw new Error(`Unsupported public key type: ${typeUrl}`);
  }
}
