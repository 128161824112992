import { Paths } from 'js/constants'

export type MenuRouteItem = {
  label: string
  link?: string
  matchExact?: boolean
  href?: string
  items?: MenuRouteItem[]
  linkArr?: string[]
} | {
  label: string
  link?: string
  matchExact?: boolean
  href: string
  items?: MenuRouteItem[]
  linkArr?: string[]
} | {
  label: string
  link?: string
  matchExact?: boolean
  href?: string
  items: MenuRouteItem[]
  linkArr?: string[]
}


export const desktopMenuItems: MenuRouteItem[] = [
  {
    label: 'Home',
    link: Paths.Dashboard,
    matchExact: true,
  },
  {
    label: 'Blockchain',
    linkArr: [
      Paths.Blocks,
      Paths.Transactions,
      Paths.Oracles,
      Paths.Validators,
      Paths.Alliance,
      Paths.Hydrogen,
      Paths.Nodes,
    ],
    items: [
      {
        label: 'Blocks',
        link: Paths.Blocks,
      },
      {
        label: 'Transactions',
        link: Paths.Transactions,
      },
      {
        label: 'Oracles',
        link: Paths.Oracles,
      },
      {
        label: 'Validators',
        link: Paths.Validators,
      },
      {
        label: 'Alliances',
        link: Paths.Alliance,
      },
      {
        label: 'Crosschain Txs',
        href: Paths.Hydrogen,
      },
      {
        label: 'API Nodes',
        link: Paths.Nodes,
      },
    ],
  },
  {
    label: 'Assets',
    linkArr: [Paths.Tokens, Paths.Markets, Paths.Pools, Paths.CDP, Paths.Group],
    items: [
      {
        label: 'Tokens',
        link: Paths.Tokens
      },
      {
        label: 'Groups',
        link: Paths.Group
      },
      {
        label: 'Markets',
        link: Paths.Markets,
      },
      {
        label: 'Pools',
        link: Paths.Pools,
      },
      {
        label: 'Borrowable Assets',
        link: Paths.CDP,
      }
    ]
  },
  {
    label: 'Trading',
    linkArr: [Paths.Leaderboard, Paths.TradingHistory, Paths.Futures,
    Paths.LiquidationAndADL],
    items: [
      {
        label: 'Leaderboard',
        link: Paths.Leaderboard,
      },
      {
        label: 'Positions',
        link: Paths.Positions,
      },
      {
        label: 'Trading History',
        link: Paths.TradingHistory,
      },
      {
        label: 'Futures History',
        link: Paths.Futures,
      },
      {
        label: 'Liquidation & ADL',
        link: Paths.LiquidationAndADL,
      },
    ],
  },
  {
    label: 'Governance',
    link: Paths.Governance,
  },
  {
    label: 'Stake',
    href: Paths.Stake
  }
]
