import { Box, BoxProps, Breadcrumbs, Link, makeStyles, Theme, Typography } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'
import clsx from 'clsx'
import React, { ReactElement } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import BackLink from './BackLink'

type Props = Omit<BoxProps, 'title'> & {
  title?: string | React.ReactNode
  rightMenu?: React.ReactNode
  backLink?: string
  backLabel?: string
  variant?: string
  breadcrumbs?: ReadonlyArray<{ href?: string, text: string } | null>
}

const Page: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const {
    title,
    className,
    children,
    rightMenu,
    backLink,
    backLabel,
    breadcrumbs,
    variant = 'h1',
    ...rest
  } = props
  const classes = useStyles(props)

  const filteredCrumbs = breadcrumbs?.filter(b => b !== null) as ReadonlyArray<{ href?: string, text: string }>

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      {backLink && (
        <BackLink to={backLink}>{backLabel || 'Back'}</BackLink>
      )}
      {filteredCrumbs?.length && <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
        {
          filteredCrumbs.map((crumb, i) => crumb.href ?
            <Link underline="none" color="inherit" component={RouterLink} to={crumb.href} key={i}>
              {crumb.text}
            </Link>
            :
            <Typography color="textPrimary" key={i}>{crumb.text}</Typography>
          )
        }
      </Breadcrumbs>
      }
      <Box className={classes.header}>
        {!!title && (
          <Typography className={classes.title} variant={variant as Variant}>
            {title}
          </Typography>
        )}
        {rightMenu}
      </Box>
      {children}
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  breadcrumbs: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.contrastText,
  }
}))

export default Page
