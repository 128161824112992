import { Box, makeStyles, TableBody, TableCell, TableHead, TableRow, Theme, Tooltip, Typography, useMediaQuery } from '@material-ui/core'
import clsx from 'clsx'
import { getTxMsgTypes, shorten, shortenHash } from 'js/helpers/utils'
import { CellLink, DataLoadSegment, ListTable, PaginationByPage, Section, TableEmptyState, TxTypeLabel } from 'js/components'
import { useTaskSubscriber } from 'js/hooks'
import { TransactionsResponse } from 'js/reducers/transactions'
import React, { ReactElement, ReactNode } from 'react'
import { SimpleMap } from 'carbon-js-sdk/lib/util/type'
import { userTransactionsPaginationLimit } from 'js/constants'
import { useSelector } from 'react-redux'
import { RootState } from 'js/store'
import { Transaction } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/misc/export'
import { switcheo } from 'js/theme/palettes/colors'

// tslint:disable:jsx-no-lambda
interface Props {
  transactions: TransactionsResponse
  title?: string
  filters?: any
  filterStatus?: boolean
  action: any
  loadName: string[]
  sectionClass?: string
  rightMenu?: React.ReactNode
  resetPage?: boolean
  setPageReset?: any
}

const Transactions: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { sectionClass, title, transactions, filters, filterStatus = false, action, loadName, rightMenu, resetPage, setPageReset } = props
  const disablePaginationForwardButton = useSelector((state: RootState) => state.account.disablePaginationForwardButton)
  const classes = useStyles()
  const smallScreen: boolean = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  )
  const [page, setPage] = React.useState(1)
  const [loading] = useTaskSubscriber(...loadName)
  const txs = transactions.data ?? []

  return (
    <Section className={clsx(classes.root, sectionClass)} title={title} rightMenu={rightMenu}>
      <DataLoadSegment loading={loading} filter={filterStatus}>
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Height</TableCell>
              <TableCell>From</TableCell>
              <TableCell>Hash</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Result</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {txs.map((txn, index) => renderTransaction(txn, index, smallScreen))}
          </TableBody>
        </ListTable>
        {txs.length > 0 && (
          <Box paddingTop={3}>
            <PaginationByPage
              isTransaction={true}
              response={transactions}
              action={action}
              filters={filters}
              page={resetPage ? 1 : page}
              setPage={setPage}
              setPageReset={setPageReset}
              removeLast={true}
              paginationLimit={userTransactionsPaginationLimit}
              disablePaginationForwardButton={disablePaginationForwardButton}
            />
          </Box>
        )}
        {txs.length === 0 && (
          <TableEmptyState itemName="transactions" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderTransaction(
    transaction: Transaction,
    index: number,
    isScreenSmall: boolean,
  ): ReactNode {
    const { hash, address, blockHeight, code } = transaction
    const [firstMsgType, ...msgTypes] = getTxMsgTypes(transaction) ?? [] as string[]
    const msgTypeCount: number = msgTypes.length
    const msgTypeCountMap: SimpleMap<number> = msgTypes.reduce((acc, msgType) => {
      const index = msgType ?? ''
      acc[index] = (acc[index] ?? 0) + 1
      return acc
    }, {} as SimpleMap<number>)
    const shortenedHash = isScreenSmall ? shortenHash(hash, 12, 12) : hash

    return (
      <TableRow key={hash + index} hover>
        <TableCell>
          <CellLink to={`/block/${blockHeight.toString()}`}>{blockHeight.toString()}</CellLink>
        </TableCell>
        {/* TODO: Get username */}
        <TableCell>
          <CellLink to={`/account/${address}`}>
            {address.length < 11 ? address : shorten(address)}
          </CellLink>
        </TableCell>
        <TableCell>
          <CellLink to={`/transaction/${hash}`}>{shortenedHash}</CellLink>
        </TableCell>
        <TableCell>
          <TxTypeLabel>{firstMsgType}</TxTypeLabel>
          {msgTypeCount > 1 && (
            <Tooltip arrow placement="top"
              title={
                msgTypes.map((message, index) => renderMessageTypes(message!, index, msgTypeCountMap, msgTypeCount))
              }>
              <Box component="span" className={classes.msgTypes}>
                <Typography>{`+`}</Typography>
                <Typography className={classes.msgCount}>{msgTypeCount}</Typography>
              </Box>
            </Tooltip>
          )}
        </TableCell>
        <TableCell className={clsx(classes.result, { isFail: code !== 0 })}>{code === 0 ? 'Success' : 'Fail'}</TableCell>
      </TableRow>
    )
  }

  function renderMessageTypes(
    msgType: string,
    index: number,
    msgTypeCountMap: SimpleMap<number>,
    msgTypesLength: number,
  ): ReactNode {
    const endLine = index === msgTypesLength - 1 ? `` : `,`
    return (
      <Typography className={classes.toolTip} key={index}>
        {`${msgTypeCountMap[msgType!]} ${msgType?.trim()}${endLine}`}
      </Typography>
    )
  }


}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 3, 3),
  },
  msgTypes: {
    marginLeft: theme.spacing(0.5),
    display: 'inline-flex',
  },
  toolTip: {
    fontSize: '0.8rem',
    textAlign: 'left',
  },
  msgCount: {
    marginLeft: theme.spacing(0.5),
    fontSize: '0.8rem',
    fontWeight: 'bold',
    height: '1.5em',
    width: '1.5em',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '2.5em',
    background: theme.palette.primary.light
  },
  result: {
    color: switcheo.green[400],
    '&.isFail': {
      color: switcheo.loss,
    }
  }
}))

export default Transactions
