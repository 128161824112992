import React from 'react'
import { useDispatch } from 'react-redux'
import PaginationButtons from './PaginationButtons'

interface Props {
  // TODO: type to ResultsMinMax<any> after migration complete
  response: any
  action: any
  filters?: any
  useBlockHeight: boolean
}

const PaginationByIndex: React.FC<Props> = ({
  response,
  action,
  filters = {},
  useBlockHeight,
}: Props) => {
  const dispatch = useDispatch()
  const data = response.data
  const firstItem = data[0]
  const lastItem = data.slice(-1)[0]

  if (data.length === 0) {
    return null
  }

  const minRef = useBlockHeight ? (lastItem.blockHeight ?? lastItem.height) : lastItem.id
  const maxRef = useBlockHeight ? (firstItem.blockHeight ?? firstItem.height) : firstItem.id

  const handleButtonClick = (event: any) => {
    if (response.data.length > 0) {
      const state: any = {}
      switch (event) {
        case 'next':
          if (useBlockHeight) {
            state.beforeBlock = minRef
          } else {
            state.beforeId = minRef
          }
          break
        case 'last':
          state.orderBy = 'asc'
          break
        case 'previous':
          if (useBlockHeight) {
            state.afterBlock = maxRef
          } else {
            state.afterId = maxRef
          }
          state.orderBy = 'asc'
          break
        case 'first':
          break
        default:
          break
      }
      dispatch(
        action({
          filters,
          pagination: state,
        }),
      )
    }
  }

  let disableBack: boolean = true
  if (
    response.data.length > 0 &&
    maxRef < response.max
  ) {
    disableBack = false
  }

  let disableFoward: boolean = true
  if (
    response.data.length > 0 &&
    minRef > response.min
  ) {
    disableFoward = false
  }
  return (
    <PaginationButtons
      handleButtonClick={handleButtonClick}
      disableBack={disableBack}
      disableForward={disableFoward}
    />
  )
}

export default PaginationByIndex
