import { Insights } from 'carbon-js-sdk'
import { Token } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/export'
import { Action } from 'redux'

export enum TokenActionType {
  CLEAR = 'CLEAR_TOKEN_INFO',
  SET_TOKEN_INFO = 'SET_TOKEN_INFO',
  SET_RICH_ACCOUNTS_BALANCES = 'SET_RICH_ACCOUNTS_BALANCES',
  SET_ORACLES_PRICE_CHART = 'SET_ORACLES_PRICE_CHART',
  UPDATE_DATE_FILTERS_ORACLES_PRICE_CHART = 'UPDATE_DATE_FILTERS_ORACLES_PRICE_CHART'
}

export type TokenAction =
  | ClearAction
  | SetTokenInfoAction
  | SetRichAccountsBalancesAction
  | SetOraclesPriceChartAction
  | UpdateDateFiltersOraclesPriceChartAction

export interface ClearAction extends Action<TokenActionType.CLEAR> { }
export function clear(): ClearAction {
  return {
    type: TokenActionType.CLEAR,
  }
}

export interface TokenWithSupply extends Token {
  totalSupply: string
}

export interface SetTokenInfoAction
  extends Action<TokenActionType.SET_TOKEN_INFO> {
  token?: TokenWithSupply
}


export function setTokenInfo(token?: TokenWithSupply): SetTokenInfoAction {
  return {
    type: TokenActionType.SET_TOKEN_INFO,
    token,
  }
}

export interface SetRichAccountsBalancesAction
  extends Action<TokenActionType.SET_RICH_ACCOUNTS_BALANCES> {
  rich: Insights.BalanceDetails[]
}

export function setRichAccountsBalances(rich: Insights.BalanceDetails[]): SetRichAccountsBalancesAction {
  return {
    type: TokenActionType.SET_RICH_ACCOUNTS_BALANCES,
    rich,
  }
}

export interface SetOraclesPriceChartAction
  extends Action<TokenActionType.SET_ORACLES_PRICE_CHART> {
  oraclesPriceChartDataResponse: Insights.OraclesPrice[]
}

export function setOraclesPriceChartData(oraclesPriceChartDataResponse: any): SetOraclesPriceChartAction {
  return {
    type: TokenActionType.SET_ORACLES_PRICE_CHART,
    oraclesPriceChartDataResponse,
  }
}

export interface UpdateDateFiltersOraclesPriceChartAction
  extends Action<TokenActionType.UPDATE_DATE_FILTERS_ORACLES_PRICE_CHART> {
  options: any
}

export function updateDateFiltersOraclesPriceChart(options: any): UpdateDateFiltersOraclesPriceChartAction {
  return {
    type: TokenActionType.UPDATE_DATE_FILTERS_ORACLES_PRICE_CHART,
    options,
  }
}