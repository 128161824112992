import { reverseHex } from '@cityofzion/neon-core/lib/u'
import { Box, Grid, ListItem, makeStyles, Theme, Typography } from '@material-ui/core'
import { toBech32Address } from '@zilliqa-js/crypto'
import { BlockchainUtils, CarbonSDK } from 'carbon-js-sdk'
import { Token } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import React, { ReactElement, useMemo } from 'react'
import { CellLink, Section, TokenIcon } from 'js/components'
import { BlockChainPaths, TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import { returnUrlNet, reverseStr } from 'js/utils'
import { getBridgeName } from '../utils'
import StandardListItem from 'js/components/StandardListItem'

interface TokenWithSupply extends Token {
  totalSupply: string
}
interface Props {
  token: TokenWithSupply
  holderCount: number
  formattedTokenName: string
}

function isTrue(params: boolean) {
  return params ? 'true' : 'false'
}

const Information: React.FunctionComponent<Props> = ({
  token, holderCount, formattedTokenName
}: Props): ReactElement<Props> => {
  const classes = useStyles()
  const sdk = useRedux(state => state.core.carbonSDK)
  const { network } = useRedux((state) => state.app)
  const [holdersLoading] = useTaskSubscriber(TaskNames.Token.RichList)

  const { blockchain, bridgeName, bridgeAddress } = useMemo(() => {
    const parts = token.bridgeAddress.split('/0x')
    const bridgeAddress = parts[parts.length - 1]
    return {
      blockchain: sdk?.token.getBlockchainV2(token.denom) ?? sdk?.token.getBlockchainV2FromIDs(Number(token.chainId.toString()), Number(token.bridgeId.toString())),
      bridgeName: getBridgeName(token.bridgeId.toString()),
      bridgeAddress
    }
  }, [token, sdk])

  const getAssetIDLink = () => {
    const paths = sdk?.network === CarbonSDK.Network.MainNet ? BlockChainPaths.mainnet : BlockChainPaths.devnet
    switch (blockchain) {
      case 'Ethereum':
      case BlockchainUtils.Blockchain.Ethereum: {
        if (token.denom === 'eth1') {
          return paths.eth.replace(':path', '/')
        }
        return paths.eth.replace(':path', `/token/0x${token.tokenAddress}`)
      }
      case 'Binance Smart Chain':
      case BlockchainUtils.Blockchain.BinanceSmartChain: {
        if (token.denom === 'bnb1') {
          return paths.bsc.replace(':path', '/')
        }
        return paths.bsc.replace(':path', `/token/0x${token.tokenAddress}`)
      }
      case BlockchainUtils.Blockchain.Zilliqa: {
        if (token.denom === 'zil1' || token.tokenAddress === '0000000000000000000000000000000000000000') {
          return paths.zil.replace(':path', '/')
        }
        return paths.zil.replace(':path', `/address/${toBech32Address(token.tokenAddress)}`)
      }
      case BlockchainUtils.Blockchain.Neo3: {
        return paths.neo3.replace(':path', `/tokens/nep17/0x${reverseHex(token.tokenAddress)}`)
      }
      case BlockchainUtils.Blockchain.Neo: {
        if (token.denom === 'nneo1') {
          return paths.neo.replace(':path', `/nep5/${token.tokenAddress}/page/1`)
        }
        return paths.neo.replace(':path', `/nep5/${reverseStr(token.tokenAddress, 2)}/page/1`)
      }
      default:
        return ''
    }
  }

  const getBridgeContractLink = () => {
    const paths = sdk?.network === CarbonSDK.Network.MainNet ? BlockChainPaths.mainnet : BlockChainPaths.devnet;
    switch (blockchain) {
      case 'Ethereum':
      case BlockchainUtils.Blockchain.Ethereum: {
        return paths.eth.replace(':path', `/address/0x${bridgeAddress}`)
      }
      case 'Binance Smart Chain':
      case BlockchainUtils.Blockchain.BinanceSmartChain: {
        return paths.bsc.replace(':path', `/address/0x${bridgeAddress}`)
      }
      case BlockchainUtils.Blockchain.Zilliqa: {
        return paths.zil.replace(':path', `/address/${toBech32Address(bridgeAddress)}`)
      }
      case BlockchainUtils.Blockchain.Neo3: {
        return paths.neo3.replace(':path', `/contract/0x${reverseHex(bridgeAddress)}`)
      }
      case BlockchainUtils.Blockchain.Neo: {
        return paths.neo.replace(':path', `/address/${reverseStr(bridgeAddress, 2)}/page/1`)
      }
      default:
        return ''
    }
  }

  const assetIDLink = getAssetIDLink();
  const bridgeContractLink = getBridgeContractLink();

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={12} sm={12} md={4}>
        <Section variant="primary" style={{ height: '100%' }}>
          <Box display="flex" alignItems="center" mb={3}>
            <TokenIcon denom={token.denom} className={classes.tokenIcon} />
            <Box ml={1.5}>
              <Typography variant="h2">{formattedTokenName}</Typography>
              <Typography variant="subtitle2" className={classes.symbol}>{token.symbol}</Typography>
            </Box>
          </Box>
          <StandardListItem label="Total Supply" value={token?.totalSupply} />
          <StandardListItem label="Holders" value={holdersLoading ? 'Loading...' : holderCount.toString()} />
        </Section>
      </Grid>
      <Grid item xs={12} sm={12} md={8}>
        <Section>
          <ListItem disableGutters>
            <div className={classes.header}>Denom</div>
            <div className={classes.string}>{token?.denom}</div>
          </ListItem>
          <ListItem disableGutters>
            <div className={classes.header}>Decimals</div>
            <div className={classes.string}>{token.decimals.toString()}</div>
          </ListItem>
          <ListItem disableGutters>
            <div className={classes.header}>Token Address</div>
            <CellLink href={assetIDLink}>
              <div className={classes.string}>{token.tokenAddress}</div>
            </CellLink>
          </ListItem>
          <ListItem disableGutters>
            <div className={classes.header}>Chain (ID)</div>
            <div className={classes.string}>{blockchain} ({token.chainId.toString()})</div>
          </ListItem>
          <ListItem disableGutters>
            <div className={classes.header}>Bridge (ID)</div>
            <div className={classes.string}>{bridgeName} ({token.bridgeId.toString()})</div>
          </ListItem>
          <ListItem disableGutters>
            <div className={classes.header}>Bridge Contract</div>
            <CellLink href={bridgeContractLink}>
              <div className={classes.string}>0x{bridgeAddress}</div>
            </CellLink>
          </ListItem>
          <ListItem disableGutters>
            <div className={classes.header}>Enabled</div>
            <div className={classes.string}>{isTrue(token.isActive)}</div>
          </ListItem>
          <ListItem disableGutters>
            <div className={classes.header}>Creator</div>
            <CellLink href={`/account/${token.creator}?net=${returnUrlNet(network)}`}>
              <div className={classes.string}>{token.creator}</div>
            </CellLink>
          </ListItem>
        </Section>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  string: {
    wordBreak: 'break-all',
  },
  header: {
    flexBasis: '33%',
    paddingRight: '1rem',
  },
  symbol: {
    color: theme.palette.grey[600],
  },
  tokenIcon: {
    width: '3em',
    height: '3em',
  }
}))

export default Information
