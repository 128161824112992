import React, { ReactElement, useEffect, useState } from 'react'
import { CarbonSDK, CarbonTx } from 'carbon-js-sdk'
import { IndexedTx } from '@cosmjs/stargate'
import { ListItem, makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { CellLink, Section } from 'js/components'
import { fromNow } from 'js/helpers/dayjs'
import { useAsyncTask, useRedux } from 'js/hooks'
import { getTxFee, getAddressFromSignerInfo } from '../utils'
import { switcheo } from 'js/theme/palettes/colors'

interface Props {
  transaction: IndexedTx,
  decodedTx?: CarbonTx.Tx
}

const TransactionDetail: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const classes = useStyles()
  const { transaction, decodedTx } = props
  const sdk = useRedux((state) => state.core.carbonSDK)
  const network = useRedux((state) => state.app.network);
  const prefix = network === CarbonSDK.Network.TestNet || network === CarbonSDK.Network.LocalHost ? 'tswth' : 'swth'

  const signerInfo = decodedTx?.authInfo?.signerInfos?.at(0) // fee payer is default the sender / first signer
  let address
  try {
    address = signerInfo ? getAddressFromSignerInfo(signerInfo, prefix) : 'Error Parsing Singer'
  } catch (err) {
    console.error(err)
    const addressMsg = (decodedTx?.body?.messages?.[0] as any)?.value // try to use the first msg if we can't parse the pub key
    address = addressMsg?.signer ?? 'Error Parsing Address'
  }

  const [blockTime, setBlockTime] = useState<dayjs.Dayjs | null>(null)
  const [runQueryBlock] = useAsyncTask('queryBlock')

  const [fee, feeSymbol] = sdk ? getTxFee(sdk, transaction) : ['', '']

  useEffect(() => {
    if (!sdk) return
    runQueryBlock(async () => {
      try {
        const chainQueryClient = sdk.query.chain
        const block = await chainQueryClient.getBlock(transaction.height)
        setBlockTime(dayjs(block.header.time))
      }
      catch (err) {
        console.error(err)
      }
    })
    // eslint-disable-next-line
  }, [sdk])

  return (
    <Section>
      <ListItem disableGutters>
        <div className={classes.header}>Transaction Hash</div>
        <div className={clsx(classes.string, classes.value)}>
          {transaction?.hash}
        </div>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>From</div>
        {/* TODO: Get username */}
        <CellLink to={`/account/${address}`} className={classes.value}>
          {address}
        </CellLink>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Result</div>
        <div className={clsx(classes.value, classes.result, { isFail: transaction?.code !== 0 })}>
          {transaction?.code === 0 ? 'Success' : 'Fail'}
        </div>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Height</div>
        <CellLink to={`/block/${transaction?.height}`} className={classes.value}>
          {transaction?.height}
        </CellLink>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Block Time</div>
        <div className={classes.value}>
          {blockTime ? blockTime.format('YYYY-MM-DD HH:mm:ss') : "-"}
          <span className={classes.timeAgo}>{blockTime ? (fromNow(blockTime)) : ""}</span>
        </div>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Network Fee</div>
        <div className={classes.value}>
          <div className={classes.mainContent}>
            {fee}
          </div>
          <div className={classes.sideContent}>
            {feeSymbol?.toUpperCase()}
          </div>
        </div>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Memo</div>
        <div className={classes.value}>{decodedTx?.body?.memo || '-'}</div>
      </ListItem>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  string: {
    wordBreak: 'break-all',
  },
  header: {
    flexBasis: '25%',
    paddingRight: '1rem',
  },
  value: {
    width: '47.5rem',
    overflowX: 'auto',
  },
  mainContent: {
    display: 'inline',
  },
  sideContent: {
    color: theme.palette.grey[600],
    display: 'inline',
    marginLeft: theme.spacing(0.75),
  },
  timeAgo: {
    marginLeft: theme.spacing(1.25),
    color: theme.palette.grey[600],
  },
  result: {
    color: switcheo.green[400],
    '&.isFail': {
      color: switcheo.loss,
    }
  }
}))

export default TransactionDetail
