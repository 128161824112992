import { makeStyles, Theme, Divider, ListItem } from '@material-ui/core'
import React, { ReactElement } from 'react'
import clsx from 'clsx'

type Props = {
  label: string,
  value: string,
  alignLeft?: boolean,
}

const StandardListItem: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const classes = useStyles()
  const { alignLeft } = props

  return <>
    <ListItem disableGutters style={{ justifyContent: 'space-between'}}>
      <div className={classes.label}>{props.label}</div>
      <div className={clsx(classes.value, { alignLeft })}>{props.value}</div>
    </ListItem>
    <Divider className={classes.divider} variant="middle" />
  </>
}

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    wordBreak: 'break-all',
  },
  value: {
    '&.alignLeft': {
      flexBasis: '33%',
      paddingRight: '1rem',
    },
  },
  divider: {
    alignSelf: 'center',
    width: '100%',
    margin: theme.spacing(0.75, 0),
  }
}))

export default StandardListItem
