import { Duration } from 'carbon-js-sdk/lib/codec'
import { bnOrZero } from 'carbon-js-sdk/lib/util/number'
import { UpdateMarketProposalFormState } from 'js/models'
import store from 'js/store'
import { adjustUnitless, escapeHtmlGo } from 'js/utils'
import { DecCoin } from 'carbon-js-sdk/lib/codec/cosmos/base/v1beta1/coin'
import { CarbonTx } from "carbon-js-sdk"
import { MsgSubmitProposal } from "carbon-js-sdk/lib/codec/cosmos/gov/v1/tx"
import { MsgUpdateMarket } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/tx'

export function updateMarketProposalValue(proposer: string | undefined, initialDeposit: DecCoin[], authority: string): MsgSubmitProposal {
  const state = store.getState()
  const formState = state.governance.submitProposalFormState as UpdateMarketProposalFormState
  const sdk = state.core.carbonSDK
  const markets = state.app.markets

  const { title, summary, metadata, id, displayName, marketDescription,
    minQuantity, lotSize, tickSize, riskStepSize, initialMarginBase,
    initialMarginStep, maintenanceMarginRatio, maxLiquidationOrderTicket,
    maxLiquidationOrderDuration, impactSize, markPriceBand, lastPriceProtectedBand, tradingBandwidth } = formState

  const market = markets[id]
  const baseTokenDecimals = bnOrZero(sdk?.token.getDecimals(market.base))
  const quoteTokenDecimals = bnOrZero(sdk?.token.getDecimals(market.quote))
  const updateMarketParams = {
    id,
    displayName,
    description: marketDescription,
    tickSize: bnOrZero(tickSize).shiftedBy(quoteTokenDecimals.toNumber() - baseTokenDecimals.toNumber()).toString(),
    lotSize: bnOrZero(lotSize).shiftedBy(baseTokenDecimals.toNumber()).toString(),
    minQuantity: bnOrZero(minQuantity).shiftedBy(baseTokenDecimals.toNumber()).toString(),

    riskStepSize: adjustUnitless(riskStepSize).toString(),
    initialMarginBase: bnOrZero(initialMarginBase).toString(),
    initialMarginStep: bnOrZero(initialMarginStep).toString(),
    maintenanceMarginRatio: bnOrZero(maintenanceMarginRatio).toString(),
    maxLiquidationOrderTicket: bnOrZero(maxLiquidationOrderTicket).toString(),
    maxLiquidationOrderDuration: Duration.fromJSON(
      { seconds: maxLiquidationOrderDuration }
    ),
    impactSize: bnOrZero(impactSize).toString(),
    markPriceBand: bnOrZero(markPriceBand).toNumber(),
    lastPriceProtectedBand: bnOrZero(lastPriceProtectedBand).toNumber(),
    isActive: true,
    tradingBandwidth: bnOrZero(tradingBandwidth).toNumber(),
  }

  const msg = MsgUpdateMarket.fromPartial({
    updater: authority,
    marketParams: updateMarketParams
  })

  const value = {
    title: escapeHtmlGo(title),
    summary: escapeHtmlGo(summary),
    metadata: escapeHtmlGo(metadata),
    expedited: false,
    initialDeposit,
    proposer: proposer ?? "",
    messages: [
      {
        typeUrl: CarbonTx.Types.MsgUpdateMarket,
        value: MsgUpdateMarket.encode(msg).finish()
      }
    ],
  }

  return value
}
