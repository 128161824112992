import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router'
import { Location, Pathname } from 'history'
import { Paths } from 'js/constants'
import ReactGA from 'react-ga'
import { match, matchPath } from 'react-router'
// import { useLocation } from 'react-router-dom'
import { SagaIterator } from 'redux-saga'
import { call, Effect, select, spawn, takeLatest } from 'redux-saga/effects'
import { parseNet } from 'js/utils'
import Account from './Account'
import Block from './Block'
import Blocks from './Blocks'
import Dashboard from './Dashboard'
import Events from './Events'
import Governance from './Governance'
import Leaderboard from './Leaderboard'
import LeaderboardHistory from './LeaderboardHistory'
import LiquidityPools from './LiquidityPools'
import Position from './Position'
import Market from './Market'
import Markets from './Markets'
import Nodes from './Nodes'
import Order from './Order'
import Saga from './Saga'
import Token from './Token'
import Tokens from './Tokens'
import Transaction from './Transaction'
import Transactions from './Transactions'
import Validator from './Validator'
import Validators from './Validators'
import TradingHistory from './TradingHistory'
import Proposal from './Proposal'
import Alliance from './Alliance'

export default class Router extends Saga {
  private saga?: Saga
  private location: Pathname
  private isMobile: boolean

  constructor() {
    super()
    this.location = ''
    this.isMobile = false
  }

  protected getStartEffects(): Effect[] {
    return [[this, this.watchLocationChange]].map(spawn)
  }

  private *watchLocationChange() {
    yield takeLatest(LOCATION_CHANGE, this.handleLocationChange.bind(this))
  }

  private *handleLocationChange(action: LocationChangeAction): SagaIterator {
    const location: Location = action.payload.location
    if (location.pathname !== Paths.Login) {
      sessionStorage.setItem('before-login', location.pathname)
    }
    this.location = location.pathname
    const isMobile = localStorage.mobile || window.navigator.maxTouchPoints > 1 || window.screen.width < 500;
    this.isMobile = isMobile

    const params = new URLSearchParams(window.location.search)
    if (params.get('net') === null) {
      const network: any = yield select((state) => state.app.network)
      const net = parseNet(network)

      params.set('net', net)
      window.history.replaceState('', '', `${location.pathname}?${params}`)
    }

    // TODO: remove to bypass sagas not working
    // if (this.location === location.pathname) {
    //   return
    // }

    ReactGA.ga('send', 'pageview', location.pathname, { transport: 'beacon' })

    for (const { path, sagaCreator } of [
      {
        path: Paths.Account,
        sagaCreator: (m: match<any>): Saga => {
          return new Account(m.params.address, params.get('msg_type'))
        },
      },
      {
        path: Paths.Blocks,
        sagaCreator: (m: match<any>): Saga => new Blocks(this.isMobile),
      },
      {
        path: Paths.Nodes,
        sagaCreator: (m: match<any>): Saga => new Nodes(this.isMobile),
      },
      {
        path: Paths.Block,
        sagaCreator: (m: match<any>): Saga => new Block(m.params.height, this.isMobile),
      },
      {
        path: Paths.Transaction,
        sagaCreator: (m: match<any>): Saga => {
          return new Transaction(m.params.hash, this.isMobile)
        },
      },
      {
        path: Paths.Transactions,
        sagaCreator: (m: match<any>): Saga => new Transactions(this.isMobile),
      },
      {
        path: Paths.Token,
        sagaCreator: (m: match<any>): Saga => {
          return new Token(m.params.denom, this.isMobile)
        },
      },
      {
        path: Paths.Tokens,
        sagaCreator: (m: match<any>): Saga => new Tokens(this.isMobile),
      },
      {
        path: Paths.LiquidationAndADL,
        sagaCreator: (m: match<any>): Saga => new Events(this.isMobile),
      },
      {
        path: Paths.Futures,
        sagaCreator: (m: match<any>): Saga => new Events(this.isMobile),
      },
      {
        path: Paths.TradingHistory,
        sagaCreator: (m: match<any>): Saga => new TradingHistory(this.isMobile),
      },
      {
        path: Paths.Markets,
        sagaCreator: (m: match<any>): Saga => new Markets(this.isMobile),
      },
      {
        path: Paths.Market,
        sagaCreator: (m: match<any>): Saga => new Market(m.params.market, this.isMobile),
      },
      {
        path: Paths.Order,
        sagaCreator: (m: match<any>): Saga => new Order(m.params.id, this.isMobile),
      },
      {
        path: Paths.Position,
        sagaCreator: (m: match<any>): Saga => new Position(m.params.address, m.params.market, m.params.status, this.isMobile),
      },
      {
        path: Paths.Validators,
        sagaCreator: (m: match<any>): Saga => new Validators(this.isMobile),
      },
      {
        path: Paths.Validator,
        sagaCreator: (m: match<any>): Saga => new Validator(m.params.address, this.isMobile),
      },
      {
        path: Paths.Proposal,
        sagaCreator: (m: match<any>): Saga => new Proposal(m.params.proposalId, this.isMobile),
      },
      {
        path: Paths.Governance,
        sagaCreator: (m: match<any>): Saga => new Governance(this.isMobile)
      },
      {
        path: Paths.Pools,
        sagaCreator: (m: match<any>): Saga => new LiquidityPools(this.isMobile),
      },
      {
        path: Paths.Leaderboard,
        sagaCreator: (m: match<any>): Saga => new Leaderboard(this.isMobile),
      },
      {
        path: Paths.LeaderboardHistory,
        sagaCreator: (m: match<any>): Saga => new LeaderboardHistory(this.isMobile),
      },
      {
        path: Paths.Dashboard,
        sagaCreator: (m: match<any>): Saga => new Dashboard(this.isMobile),
      },
      {
        path: Paths.Alliance,
        sagaCreator: (m: match<any>): Saga => new Alliance(),
      }
    ]) {
      const m: match<any> | null = matchPath(location.pathname, { path })
      if (m) {
        yield call([this, this.startSaga], sagaCreator(m))
        return
      }
    }
  }

  private * startSaga(saga: Saga): SagaIterator {
    if (this.saga) {
      yield* this.saga.stop()
    }
    yield* saga.start()
    this.saga = saga
  }
}
