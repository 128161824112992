import { Typography } from '@material-ui/core'
import { CarbonTx } from 'carbon-js-sdk'
import { CellLink, DataLoadSegment, NotFoundState, Page } from 'js/components'
import { Paths, TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import React, { Fragment, ReactElement } from 'react'
import { TransactionDetail, TransactMessages } from './components'
import { shortenHash } from 'js/helpers'
import { useParams } from 'react-router-dom'

interface Props {
}

const Transaction: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const [loading] = useTaskSubscriber(TaskNames.Transaction.Detail)
  const { transaction } = useRedux((state) => state.transaction)
  const network = useRedux((state) => state.app.network)
  const params = useParams();

  let decodedTx: CarbonTx.Tx | undefined
  try {
    decodedTx = CarbonTx.decode(transaction?.tx)
  }
  catch (err) {
    console.error(err)
  }
  return (
    <Page title="Transaction Details" breadcrumbs={[{ href: '/transactions', text: 'Transactions'}, { text: shortenHash(params.hash, 8, 8) }]}>
      <DataLoadSegment loading={loading}>
        {transaction?.hash && (
          <Fragment>
            <TransactionDetail transaction={transaction} decodedTx={decodedTx} />
            <TransactMessages transaction={transaction} decodedTx={decodedTx} network={network} />
          </Fragment>
        )}
        {!transaction?.hash && (
          <NotFoundState title="No Transaction Found">
            <Typography variant="body1">
              We can’t find any transaction with this hash. Please check your network setting or go back to the&nbsp;
              <CellLink to={Paths.Transactions}>
                Transactions
              </CellLink>
              &nbsp;page to view existing transactions.
            </Typography>
          </NotFoundState>
        )}
      </DataLoadSegment>
    </Page>
  )
}

export default Transaction
