"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Witness = void 0;
const u_1 = require("../../u");
const wallet_1 = require("../../wallet");
/**
 * A Witness is a section of VM code that is ran during the verification of the transaction.
 *
 * For example, the most common witness is the VM Script that pushes the ECDSA signature into the VM and calling CHECKSIG to prove the authority to spend the TransactionInputs in the transaction.
 */
class Witness {
    constructor(obj) {
        // tslint:disable-next-line:variable-name
        this._scriptHash = "";
        if (!obj ||
            obj.invocationScript === undefined ||
            obj.verificationScript === undefined) {
            throw new Error("Witness requires invocationScript and verificationScript fields");
        }
        this.invocationScript = obj.invocationScript;
        this.verificationScript = obj.verificationScript;
    }
    static deserialize(hex) {
        const ss = new u_1.StringStream(hex);
        return this.fromStream(ss);
    }
    static fromStream(ss) {
        const invocationScript = ss.readVarBytes();
        const verificationScript = ss.readVarBytes();
        return new Witness({ invocationScript, verificationScript });
    }
    static fromSignature(sig, publicKey) {
        const invocationScript = "40" + sig;
        const verificationScript = wallet_1.getVerificationScriptFromPublicKey(publicKey);
        return new Witness({ invocationScript, verificationScript });
    }
    /**
     * Builds a multi-sig Witness object.
     * @param tx Hexstring to be signed.
     * @param sigs Unordered list of signatures.
     * @param acctOrVerificationScript Account or verification script. Account needs to be the multi-sig account and not one of the public keys.
     */
    static buildMultiSig(tx, sigs, acctOrVerificationScript) {
        const verificationScript = typeof acctOrVerificationScript === "string"
            ? acctOrVerificationScript
            : acctOrVerificationScript.contract.script;
        const publicKeys = wallet_1.getPublicKeysFromVerificationScript(verificationScript);
        const orderedSigs = Array(publicKeys.length).fill("");
        sigs.forEach((element) => {
            if (typeof element === "string") {
                const position = publicKeys.findIndex((key) => wallet_1.verify(tx, element, key));
                if (position === -1) {
                    throw new Error(`Invalid signature given: ${element}`);
                }
                orderedSigs[position] = element;
            }
            else if (element instanceof Witness) {
                const keys = wallet_1.getPublicKeysFromVerificationScript(element.verificationScript);
                if (keys.length !== 1) {
                    throw new Error("Given witness contains more than 1 public key!");
                }
                const position = publicKeys.indexOf(keys[0]);
                orderedSigs[position] = wallet_1.getSignaturesFromInvocationScript(element.invocationScript)[0];
            }
            else {
                throw new Error("Unable to process given signature");
            }
        });
        const signingThreshold = wallet_1.getSigningThresholdFromVerificationScript(verificationScript);
        const validSigs = orderedSigs.filter((s) => s !== "");
        if (validSigs.length < signingThreshold) {
            throw new Error(`Insufficient signatures: expected ${signingThreshold} but got ${validSigs.length} instead`);
        }
        return new Witness({
            invocationScript: validSigs
                .slice(0, signingThreshold)
                .map((s) => "40" + s)
                .join(""),
            verificationScript,
        });
    }
    get scriptHash() {
        if (this._scriptHash) {
            return this._scriptHash;
        }
        else if (this.verificationScript) {
            this._scriptHash = u_1.reverseHex(u_1.hash160(this.verificationScript));
            return this._scriptHash;
        }
        else {
            throw new Error("Unable to produce scriptHash from empty verificationScript");
        }
    }
    set scriptHash(value) {
        if (this.verificationScript) {
            throw new Error("Unable to set scriptHash when verificationScript is not empty");
        }
        this._scriptHash = value;
    }
    serialize() {
        const invoLength = u_1.num2VarInt(this.invocationScript.length / 2);
        const veriLength = u_1.num2VarInt(this.verificationScript.length / 2);
        return (invoLength + this.invocationScript + veriLength + this.verificationScript);
    }
    export() {
        return {
            invocationScript: this.invocationScript,
            verificationScript: this.verificationScript,
        };
    }
    equals(other) {
        return (this.invocationScript === other.invocationScript &&
            this.verificationScript === other.verificationScript);
    }
    generateScriptHash() {
        this._scriptHash = u_1.reverseHex(u_1.hash160(this.verificationScript));
    }
}
exports.Witness = Witness;
exports.default = Witness;
