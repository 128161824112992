import { Box, BoxProps, Container, makeStyles, Theme } from '@material-ui/core'
import { ScrollReset } from 'js/components'
import { Paths } from 'js/constants'
import { switcheo } from 'js/theme/palettes/colors'
import { Account } from 'js/views/Account'
import { Alliance } from 'js/views/Alliance'
import { Block, Blocks } from 'js/views/Blocks'
import { CDPAsset, CDPAssetsList } from 'js/views/Cdp'
// import { Deposit } from 'js/views/Deposit'
import { Governance, ProposalProp, SubmitProposal } from 'js/views/Governance'
import { GroupList } from 'js/views/Group'
import { OracleList } from 'js/views/Oracles'
import { Home } from 'js/views/Home'
import { Insights } from 'js/views/InsightSettings'
import { Leaderboard } from 'js/views/Leaderboard'
// import { Leaderboard } from 'js/views/Leaderboard'
// import { LeaderboardHistory } from 'js/views/LeaderboardHistory'
import { LiquidityPools } from 'js/views/LiquidityPools'
import PoolDetails from 'js/views/LiquidityPools/components/IndividualPool/IndividualPool'
import {
  Futures,
  LiquidationAndADL,
  // Events,
  Market, Markets, TradingHistory
} from 'js/views/Markets'
import { Nodes } from 'js/views/Nodes'
import { Order } from 'js/views/Order'
import { Position } from 'js/views/Position'
import { NoSearchResults } from 'js/views/Search'
import { Validator, Validators } from 'js/views/Staking'
import { Token, TokenList } from 'js/views/Tokens'
import { Transaction, TransactionList } from 'js/views/Transactions'
import { Login } from 'js/views/UserAccount'
import React, { ReactElement } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AllPositions } from 'js/views/AllPositions'
import Chatbot from 'js/views/Home/components/ChatBot'

const Body: React.FunctionComponent<BoxProps> = (): ReactElement<BoxProps> => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <ScrollReset />
      <Container className={classes.container} maxWidth="lg">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
          className={classes.toastContainer}
          toastClassName={classes.toast}
        />
        <Switch>
          <Route exact path={Paths.Dashboard} component={Home} />
          <Route exact path={Paths.Account} component={Account} />
          <Route exact path={Paths.Alliance} component={Alliance} />
          <Route exact path={Paths.Blocks} component={Blocks} />
          <Route exact path={Paths.Block} component={Block} />
          <Route exact path={Paths.Transaction} component={Transaction} />
          <Route exact path={Paths.Transactions} component={TransactionList} />
          <Route exact path={Paths.Tokens} component={TokenList} />
          <Route exact path={Paths.Token} component={Token} />
          <Route exact path={Paths.LiquidationAndADL} component={LiquidationAndADL} />
          <Route exact path={Paths.Futures} component={Futures} />
          <Route exact path={Paths.Markets} component={Markets} />
          <Route exact path={Paths.Market} component={Market} />
          <Route exact path={Paths.Nodes} component={Nodes} />
          <Route exact path={Paths.Order} component={Order} />
          <Route exact path={Paths.Position} component={Position} />
          <Route exact path={Paths.Validators} component={Validators} />
          <Route exact path={Paths.Validator} component={Validator} />
          {/* <Route exact path={Paths.Deposit} component={Deposit} /> */}
          <Route exact path={Paths.Governance} component={Governance} />
          <Route exact path={Paths.Proposal} component={ProposalProp} />
          <Route exact path={Paths.SubmitProposal} component={SubmitProposal} />
          <Route exact path={Paths.Login} component={Login} />
          <Route exact path={Paths.Search} component={NoSearchResults} />
          <Route exact path={Paths.Pools} component={LiquidityPools} />
          <Route exact path={Paths.Pool} component={PoolDetails} />
          <Route exact path={Paths.Leaderboard} component={Leaderboard} />
          <Route exact path={Paths.CDP} component={CDPAssetsList} />
          <Route exact path={Paths.CDPAsset} component={CDPAsset} />
          <Route exact path={Paths.Group} component={GroupList} />
          <Route exact path={Paths.Oracles} component={OracleList} />
          <Route exact path={Paths.TradingHistory} component={TradingHistory} />
          <Route exact path={Paths.Insights} component={Insights} />
          <Route exact path={Paths.Positions} component={AllPositions} />
          <Route exact path={Paths.Chatbot} component={Chatbot} />
          {/* <Route exact path={Paths.LeaderboardHistory} component={LeaderboardHistory} /> */}
          <Redirect to={Paths.Dashboard} />
        </Switch>
      </Container>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 'calc(100vh - 20rem)',
    paddingTop: '9rem',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '4rem',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '3.5625rem',
    },
  },
  container: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
  toastContainer: {
    top: '10rem',
    width: '100%',
    maxWidth: '45rem',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      top: '4rem',
    },
  },
  toast: {
    background: switcheo.background.grey,
    borderRadius: 4,
  },
}))

export default Body
