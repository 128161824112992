import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { CellLink, DataLoadSegment, ListTable, Section, TableEmptyState } from 'js/components'
import { TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import React, { ReactElement, ReactNode } from 'react'
import { bnOrZero, BN_ZERO } from 'js/utils'
import { Insights } from 'carbon-js-sdk'

interface Props {
  rich: Insights.BalanceDetails[]
}

const RichList: React.FunctionComponent<Props> = ({
  rich,
}: Props): ReactElement<Props> => {
  const [loading] = useTaskSubscriber(TaskNames.Token.RichList)
  const sdk = useRedux(state => state.core.carbonSDK)
  return (
    <Section title="Holders">
      <DataLoadSegment loading={loading}>
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Account</TableCell>
              <TableCell align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{!!(rich?.length) && rich.map(renderRichAccount)}</TableBody>
        </ListTable>
        {!rich?.length && (
          <TableEmptyState itemName="accounts" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderRichAccount(balanceDetails: Insights.BalanceDetails): ReactNode {
    const { address, balance, denom } = balanceDetails
    const balanceBN = sdk?.token.toHuman(denom, bnOrZero(balance)) ?? BN_ZERO
    const decimals = sdk?.token.getDecimals(denom) ?? 2
    return (
      <TableRow key={address} hover>
        <TableCell>
          <CellLink to={`/account/${address}`}>
            {address}
          </CellLink>
        </TableCell>
        <TableCell align="right">{balanceBN.toFormat(decimals)}</TableCell>
      </TableRow>
    )
  }
}

export default RichList
