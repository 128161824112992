import { Box, makeStyles, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import { BigNumber } from 'bignumber.js'
import { sha256 } from 'js-sha256'
import { AmountDenomCell, CellLink, DataLoadSegment, ListTable, Section, TableCellInfo, TableEmptyState, TableSectionCell } from 'js/components'
import PaginationByData from 'js/components/PaginationByData'
import { shorten, shortenHash } from 'js/helpers'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import { switcheo } from 'js/theme/palettes/colors'
import { bnOrZero } from 'js/utils'
import moment from 'moment'
import React, { ReactElement, ReactNode, useState } from 'react'

interface Position {
  address: string
  allocatedMarginAmount: string
  closedAt: string
  closedBlockHeight: string
  entryPrice: string
  estLiquidationPrice: string
  lots: string
  market: string
  openedAt: string
  openedBlockHeight: string
  realizedPnl: string
  unrealizedPnl: string
  updatedBlockHeight: string
  username: string
}

interface Props {
  positions?: Position[],
  view: "open" | "closed"
  title: string
  tasks: any
  action: any
}

const HeaderCellsOpen: TableCellInfo[] = [
  { align: 'inherit', content: 'ID' },
  { align: 'inherit', content: 'Address' },
  { align: 'inherit', content: 'Market' },
  // { align: 'right', content: 'Leverage' },
  { align: 'right', content: 'Realised PnL' },
  { align: 'right', content: 'Unrealised PnL' },
  { align: 'right', content: 'Total PNL' },
  { align: 'right', content: 'Size' },
  { align: 'right', content: 'Allocated Margin' },
  { align: 'right', content: 'Opened At' }
]

const HeaderCellsClosed: TableCellInfo[] = [
  { align: 'inherit', content: 'ID' },
  { align: 'inherit', content: 'Address' },
  { align: 'left', content: 'Market' },
  { align: 'right', content: 'Realised PnL' },
  { align: 'right', content: 'Opened At' },
  { align: 'right', content: 'Closed At' }
]

const Positions: React.FunctionComponent<Props> = ({
  positions,
  title,
  tasks,
  view,
  action,
}: Props): ReactElement<Props> => {
  const classes = useStyles()
  const [loading] = useTaskSubscriber(tasks)
  const markets = useRedux((state) => state.app.markets)
  const sdk = useRedux((state) => state.core.carbonSDK)
  const [page, setPage] = useState(1)
  const itemsPerPage = 10
  const HeaderCells = view === 'open' ? HeaderCellsOpen : HeaderCellsClosed
  const renderPositions = view === 'open' ? renderOpenPositions : renderClosedPositions
  return (
    <Section title={title}>
      <DataLoadSegment loading={loading || !positions}>
        <ListTable>
          <TableHead>
            <TableRow>
              {HeaderCells.map((header: TableCellInfo, index: number) => (
                <TableSectionCell key={index} {...header} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {positions?.slice((page - 1) * itemsPerPage, ((page - 1) * itemsPerPage) + itemsPerPage).map(renderPositions)}
          </TableBody>
        </ListTable>

        {positions?.length && (
          <Box marginTop={3}>
            <PaginationByData
              data={positions}
              setPage={setPage}
              page={page}
              itemsPerPage={itemsPerPage}
            />
          </Box>
        )}
        {(!positions || positions?.length <= 0) && (
          <TableEmptyState itemName="positions" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderOpenPositions(
    p: Position,
    index: number,
  ): ReactNode {
    const realizedPnlBN: BigNumber = new BigNumber(p?.realizedPnl)
    const unrealizedPnlBN: BigNumber = new BigNumber(p?.unrealizedPnl)
    const allocatedMarginAmountBN: BigNumber = new BigNumber(p?.allocatedMarginAmount)
    const totalPnlBN: BigNumber = realizedPnlBN.plus(unrealizedPnlBN)
    const leverage = (new BigNumber(p.entryPrice).multipliedBy(p.lots)).div(bnOrZero(p.allocatedMarginAmount)).abs().toFormat(1)
    const id = shortenHash(sha256(p.address + p.market + p.openedBlockHeight).toString(), 2).toLocaleUpperCase()
    const marketInfo = markets[p.market]
    const sizeNotion = bnOrZero(p.lots).multipliedBy(bnOrZero(p.entryPrice)).abs().toFormat(3)
    const tokenName = sdk?.token.getTokenName(marketInfo?.base ?? '') ?? ''
    const lotsBN = new BigNumber(p.lots)
    return (
      <TableRow key={p?.address + 'open' + p.openedBlockHeight} hover>
        <TableCell style={{ width: '60px' }}>
          <CellLink to={`/position/${p.address}/${encodeURIComponent(p.market)}/${p.openedBlockHeight}`}>
            {id}
          </CellLink>
        </TableCell>
        <TableCell>
          <CellLink to={`/account/${p?.address}`}>
            {shortenHash(p?.address ?? "-", 7)}
          </CellLink>
        </TableCell>
        <TableCell>
          <CellLink to={`/market/${encodeURIComponent(p.market)}`}>
            {shorten(marketInfo?.displayName ?? p.market, 8)}
          </CellLink>
        </TableCell>
        {/* <TableCell align='right'>
          {leverage}
        </TableCell> */}
        <AmountDenomCell align={'right'} amount={realizedPnlBN.toFormat(3)} denom="USD" />
        <AmountDenomCell align={'right'} amount={unrealizedPnlBN.toFormat(3)} denom="USD" />
        <AmountDenomCell align={'right'} amount={totalPnlBN.toFormat(3)} denom="USD" />
        <TableCell align="right">
          <div className={classes.fees}>
            <span style={{ color: lotsBN.lt(0) ? switcheo.red : switcheo.green[400] }}>{lotsBN.toFormat(bnOrZero(marketInfo?.lotSize).dp() ?? 3)}</span> <div className={classes.denom}>{tokenName}</div>
          </div>
          <Typography variant="caption" style={{ fontWeight: 500, color: switcheo.text.grey }}>(${sizeNotion})</Typography>
        </TableCell>
        <TableCell align="right">
          <div className={classes.fees}>
            {allocatedMarginAmountBN.toFormat(3)} <div className={classes.denom}>USD</div>
          </div>
          <Typography variant="caption" style={{ fontWeight: 500, color: switcheo.text.grey }}>Leverage: {leverage}</Typography>
        </TableCell>
        <TableCell align="right">
          <CellLink to={`/block/${p.openedBlockHeight}`}>
            {p.openedBlockHeight} <br />
            <Typography variant="caption" style={{ fontSize: '0.7rem', fontWeight: 500, color: switcheo.text.grey }}>({moment(p.openedAt).format('lll')})</Typography>
          </CellLink>
        </TableCell>
      </TableRow>
    )
  }

  function renderClosedPositions(
    p: Position,
    index: number,
  ): ReactNode {
    const realizedPnlBN: BigNumber = new BigNumber(p?.realizedPnl)
    const id = shortenHash(sha256(p.address + p.market + p.openedBlockHeight).toString(), 6).toLocaleUpperCase()
    const marketInfo = markets[p.market]
    return (
      <TableRow key={p?.address + 'closed' + p.openedBlockHeight} hover>
        <TableCell style={{ width: '60px' }}>
          <CellLink to={`/position/${p.address}/${encodeURIComponent(p.market)}/${p.openedBlockHeight}`}>
            {id}
          </CellLink>
        </TableCell>
        <TableCell>
          <CellLink to={`/account/${p?.address}`}>
            {p?.address ?? "-"}
          </CellLink>
        </TableCell>
        <TableCell align='left'>
          <CellLink to={`/market/${encodeURIComponent(p.market)}`}>
            {shorten(marketInfo?.displayName ?? p.market, 8)}
          </CellLink>
        </TableCell>
        <AmountDenomCell align={'right'} amount={realizedPnlBN.toFormat(3)} denom="USD" />
        <TableCell align="right">
          <CellLink to={`/block/${p.openedBlockHeight}`}>
            {p.openedBlockHeight} <br />
            <Typography variant="caption" style={{ fontSize: '0.7rem', fontWeight: 500, color: switcheo.text.grey }}>({moment(p.openedAt).format('lll')})</Typography>
          </CellLink>
        </TableCell>
        <TableCell align="right">
          <CellLink to={`/block/${p.closedBlockHeight}`}>
            {p.closedBlockHeight} <br />
            <Typography variant="caption" style={{ fontSize: '0.7rem', fontWeight: 500, color: switcheo.text.grey }}>({moment(p.closedAt).format('lll')})</Typography>
          </CellLink>
        </TableCell>
      </TableRow>
    )
  }
}

const useStyles = makeStyles(() => ({
  dateParams: {
    display: 'flex',
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20,
  },
  time: {
    color: grey[500],
  },
  fees: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  denom: {
    color: grey[500],
    marginLeft: 6,
    minWidth: 28,
    textAlign: 'left',
  },
}))

export default Positions
