import { Box, Button, Grid, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core'
import TwitterIcon from '@material-ui/icons/Twitter'
import axios from 'axios'
import { BigNumber } from 'bignumber.js'
import { TokenUtils } from 'carbon-js-sdk'
import { Market } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/market'
import { BN_ZERO } from 'carbon-js-sdk/lib/util/number'
import { CellLink, DataLoadSegment, NotFoundState, Page, Section, TableEmptyState } from 'js/components'
import PaginationByData from 'js/components/PaginationByData'
import { Paths, TaskNames } from 'js/constants'
import { getInitialMarginFraction, shorten } from 'js/helpers'
import estLiqPrice, { getRequiredMM } from 'js/helpers/liquidation'
import { useAsyncTask, useRedux, useTaskSubscriber } from 'js/hooks'
import { switcheo } from 'js/theme/palettes/colors'
import { adjustHuman, BIG_ZERO, bnOrZero, SHIFT_DECIMALS } from 'js/utils'
import { getAdjustedMarket } from 'js/utils/market'
import React, { ReactElement, useEffect, useState } from 'react'
import { OrderUserDetails, RelatedTrades } from '../Order/components'
import { PositionDetails } from './components'
import PositionHistoryChart from './components/PositionHistoryChart'
import { QueryTradesRequest, TradeEvent } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/broker/export'

interface Props { }

interface CollectiveTrades {
  avgPrice: BigNumber,
  fees: BigNumber,
  denom: string
}

const Position: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const classes = useStyles()
  const [loading] = useTaskSubscriber(TaskNames.Position.Detail, TaskNames.App.GeckoCoin, TaskNames.Account.Profile, TaskNames.App.Markets)
  //APIs
  const { position } = useRedux((state) => state.position)
  const { markets } = useRedux((state) => state.app)
  const sdk = useRedux((state) => state.core.carbonSDK)
  const [getTrades, tradeLoading] = useAsyncTask('getTrades') //related trades
  const [getProfile, profileLoading] = useAsyncTask('getProfile') //for twitter profile
  const [getMarketStats, marketsLoading] = useAsyncTask('getMarketStats') //tick_size .etc
  const [getLeverage, leverageLoading] = useAsyncTask('getLeverage') //get margin leverage
  const [getMarket, marketLoading] = useAsyncTask('getMarket') //get params needed for liquidation
  const [getPositionHistory, positionHistoryLoading] = useAsyncTask('getPositionHistory')
  const [getPositionFunding, positionFundingLoading] = useAsyncTask('getPositionFunding')
  //States
  const [currentPrice, setCurrentPrice] = useState<number>(0)
  const [token, setToken] = useState<string>("")
  const [dollarValue, setDollarValue] = useState<number>(0)
  const [side, setSide] = useState<string>("")
  const [trades, setTrades] = useState<TradeEvent[]>([])
  const [twitter, setTwitter] = useState<string>("")
  const [demexPrice, setDemexPrice] = useState<string>("")
  const [address, setAddress] = useState<string>("")
  const [positionId, setPositionId] = useState<string>("")
  // const [positionNotFound, setPositionNotFound] = useState<Boolean>(false)
  const [takerOrMaker, setTakerOrMaker] = useState<string>("taker")
  const [marketDetails, setMarketDetails] = useState<Market | undefined>()
  const [leverage, setLeverage] = useState<string>("1")
  const [estLiquidation, setEstLiquidation] = useState<BigNumber>(new BigNumber(0))
  const [createdTime, setCreatedTime] = useState<Date | undefined>(undefined)
  const [collectiveTrades, setCollectiveTrades] = useState<CollectiveTrades>({ avgPrice: new BigNumber(0), fees: new BigNumber(0), denom: "" })
  const [positionStatus, setPositionStatus] = useState<string>("")
  const [isPnlMoreThanZero, setIsPnlMoreThanZero] = useState<boolean | undefined>(undefined)
  const [positionHistory, setPositionHistory] = useState<any>(undefined)
  const [positionFundingFee, setPositionFundingFee] = useState<number | undefined>(undefined)
  //For pagination
  const [page, SetPage] = useState<number>(1)
  const itemsPerPage = 5
  const shareMsg = isPnlMoreThanZero ? `🌙 TO THE MOOOON!! Check out this position on %23Demex.` : isPnlMoreThanZero === undefined ? `Check out this position on %23Demex!` : `🔴 Rekkkkttt. Condolences to this position on %23Demex.`

  useEffect(() => {
    if (!position || !sdk || !address) return
    getPositionHistory(async () => {
      const networkURL = sdk?.network === 'mainnet' ? '' : sdk?.network === 'testnet' ? 'test-' : 'dev-'
      const history = await axios.get(`https://${networkURL}api-insights.carbon.network/position/history/${position.address}?market=${position.marketId}&startBlockHeight=${position.openedBlockHeight}&endBlockHeight=${position.closedBlockHeight}`)
      setPositionHistory(history?.data?.result?.entries?.filter((o: any) => !!o.time && o.mark) ?? [])
    })
    getPositionFunding(async () => {
      //get funding fee
      const networkURL = sdk?.network === 'mainnet' ? '' : sdk?.network === 'testnet' ? 'test-' : 'dev-'
      const fundingFeeResult = await axios.get(`https://${networkURL}api-insights.carbon.network/position/fundingfee/${position.address}?market=${position.marketId}&openedBlockHeight=${position.openedBlockHeight}`)
      setPositionFundingFee(Number(fundingFeeResult?.data?.result?.fundingFee ?? 0))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk, address, position])

  useEffect(() => {
    if (!position || !sdk || !address) return
    getLeverage(async () => {
      try {
        const leverageQueryClient = sdk.query.leverage
        const result = await leverageQueryClient.Leverage({
          address,
          marketId: position.marketId
        })
        setLeverage(bnOrZero(result.marketLeverage?.leverage!).shiftedBy(-SHIFT_DECIMALS).toString())
      }
      catch (err) {
        console.error(err)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, sdk, address])

  useEffect(() => {
    if (!position || !sdk) return
    getTrades(async () => {
      try {
        const brokerQueryClient = sdk.query.broker
        const result = await brokerQueryClient.Trades(
          QueryTradesRequest.fromPartial({
            address: position.address,
            marketId: position.marketId,
            afterBlock: Number(position.openedBlockHeight) - 1,
            beforeBlock: Number(position.closedBlockHeight) ? Number(position.closedBlockHeight) + 1 : 0
          })
        )
        const positionTrades = result.trades?.sort((a: any, b: any) => a?.blockHeight - b?.blockHeight)
        if (positionTrades && positionTrades.length > 0) {
          if (positionTrades[0].takerAddress === position.address) {
            setTakerOrMaker("taker")
            setSide(positionTrades[0].takerSide)
            setAddress(positionTrades[0].takerAddress)
            setPositionId(positionTrades[0].takerId)
          }
          else {
            setTakerOrMaker("maker")
            setSide(positionTrades[0].makerSide)
            setAddress(positionTrades[0].makerAddress)
            setPositionId(positionTrades[0].makerId)
          }
          setCreatedTime(positionTrades[0].blockCreatedAt)
        }
        setTrades(positionTrades)
      }
      catch (err) {
        console.error(err)
      }
    })

    getProfile(async () => {
      try {
        const profileQueryClient = sdk.query.profile
        const result = await profileQueryClient.Profile({ address: position.address })
        const profile = result.profile
        setTwitter(profile?.twitter ?? '')
      }
      catch (err) {
        console.error(err)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, sdk])

  useEffect(() => {
    if (demexPrice && position && side) {
      const market = markets[position.marketId]
      const baseDenom = market?.base ?? ''
      const quoteDenom = market?.quote ?? ''
      const quantityBN = sdk?.token.toHuman(baseDenom, bnOrZero(position.maxLots)) ?? BIG_ZERO
      const entryPriceBN = adjustHuman(position.avgEntryPrice).shiftedBy(market?.basePrecision.sub(market?.quotePrecision).toNumber())
      const demexPriceBN = new BigNumber(demexPrice)
      const realizedPnlBN = sdk?.token.toHuman(quoteDenom, bnOrZero(position.realizedPnl)) ?? BIG_ZERO
      const unrealizedPnlBN = side === "buy" ? demexPriceBN.minus(entryPriceBN).times(quantityBN.abs()) : entryPriceBN.minus(demexPriceBN).times(quantityBN.abs())
      const totalPnlBN = realizedPnlBN.plus(unrealizedPnlBN)
      if (totalPnlBN.isGreaterThan(0)) setIsPnlMoreThanZero(true)
      else if (totalPnlBN.isLessThan(0)) setIsPnlMoreThanZero(false)
    }
  }, [demexPrice, position, side, markets, sdk])

  //get current price
  useEffect(() => {
    if (!position || !sdk || loading) return
    //Set tick_size, marketType ..etc
    setMarketDetails(markets[position.marketId])
    //set position status
    const closedBlockHeightBN = bnOrZero(position.closedBlockHeight.toNumber())
    setPositionStatus(closedBlockHeightBN.isGreaterThan(0) ? "Closed" : "Open")

    //Get token price in USD
    const marketDetails = markets[position.marketId]
    const baseDenom = marketDetails.base ?? ''
    const quoteDenom = marketDetails.quote ?? ''
    const symbolOverride = marketDetails?.marketType === 'futures' ? TokenUtils.FuturesDenomOverride : undefined
    const baseSymbol = sdk?.token.getTokenName(baseDenom ?? '', symbolOverride) ?? ''
    const baseDenomUSD = sdk?.token.getUSDValue(baseDenom) ?? BN_ZERO
    const quoteDenomUSD = sdk?.token.getUSDValue(quoteDenom) ?? BN_ZERO
    const currentPrice = quoteDenomUSD.isZero() ? BN_ZERO : baseDenomUSD.div(quoteDenomUSD)
    setCurrentPrice(currentPrice.toNumber())
    setDollarValue(quoteDenomUSD.toNumber())
    setToken(baseSymbol.toUpperCase())

    //API for getting demex price
    getMarketStats(async () => {
      try {
        const allMarketStats = (await sdk.query.marketstats.MarketStats({})).marketstats
        const marketStats = allMarketStats.find((o) => o.marketId === position.marketId)
        const market = markets[position.marketId]
        const decimals = Number(market.basePrecision) - Number(market.quotePrecision)
        setDemexPrice(bnOrZero(marketStats?.markPrice).shiftedBy(-SHIFT_DECIMALS + decimals).toString() ?? '')
      }
      catch (err) {
        console.error(err)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, loading])

  //get Est Liquidation Price using getMarket information
  useEffect(() => {
    if (!position || !sdk || loading) return
    getMarket(async () => {
      try {
        const marketQueryClient = sdk.query.market
        const isPositionOpen = position.closedBlockHeight.equals(0)
        const marketResponse = await marketQueryClient.Market({ id: position.marketId })
        const market = getAdjustedMarket(marketResponse.marketId!, sdk)
        // const baseDenom = market?.base ?? ''
        const quoteDenom = market?.quote ?? ''
        const lotsBN = bnOrZero(isPositionOpen ? position.lots : position.maxLots).shiftedBy(-market?.basePrecision.toNumber()) ?? BIG_ZERO
        const initialMarginBaseBN = bnOrZero(market?.initialMarginBase)
        const riskStepSizeBN = bnOrZero(market?.riskStepSize)
        const initialMarginStepBN = bnOrZero(market?.initialMarginStep)
        const entryPriceBN = adjustHuman(position.avgEntryPrice).shiftedBy(market?.basePrecision.sub(market?.quotePrecision).toNumber())
        const allocatedMarginAmountBN = sdk?.token.toHuman(quoteDenom, adjustHuman(isPositionOpen ? position.allocatedMargin : position.avgAllocatedMargin)) ?? BIG_ZERO
        const iniMarginFraction = getInitialMarginFraction(
          lotsBN,
          initialMarginBaseBN,
          riskStepSizeBN,
          initialMarginStepBN
        )
        const mmr = bnOrZero(market?.maintenanceMarginRatio)
        const requiredMM = getRequiredMM(iniMarginFraction, mmr)
        const estLiq = estLiqPrice(
          requiredMM,
          lotsBN,
          entryPriceBN,
          allocatedMarginAmountBN,
        )
        setEstLiquidation(estLiq)
      }
      catch (err) {
        console.error(err)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, loading])


  useEffect(() => {
    if (!loading && trades.length > 0) {
      setCollectiveTrades(collectiveTrade => {
        let totalQuantity = new BigNumber(0)
        let avgPrice = new BigNumber(0);
        let fees = new BigNumber(0);
        for (let i = 0; i < trades.length; i++) {
          totalQuantity = totalQuantity.plus(trades[i].quantity)
          avgPrice = avgPrice.plus(Number(trades[i].price) * Number(trades[i].quantity))
          fees = fees.plus(Number(trades[i][takerOrMaker === "taker" ? "takerFeeAmount" : "makerFeeAmount"]))
        }
        collectiveTrade.avgPrice = avgPrice.div(totalQuantity)
        collectiveTrade.fees = fees
        collectiveTrade.denom = trades[0][takerOrMaker === "taker" ? "takerFeeDenom" : "makerFeeDenom"]
        return collectiveTrade
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [takerOrMaker, trades.length])

  const breadcrumbs = [
    { href: '/positions', text: 'Positions'},
    position?.address ? { href: `/account/${encodeURIComponent(position.address)}&tab=positions`, text: shorten(position.address) } : null,
    { text: 'Position Details' }
  ]

  // If trading pair is not found , return NotFoundState
  if (!loading && !position?.address) return (
    <Page breadcrumbs={breadcrumbs}>
      <NotFoundState title="Position not found">
        <Typography variant="body1">
          We can't find any position with this ID. Please check your network setting or go back to the&nbsp;
          <CellLink to={Paths.Leaderboard}>
            leaderboard
          </CellLink>
          &nbsp;page to view existing positions.
        </Typography>
      </NotFoundState>
    </Page>
  )
  return (
    <Page  breadcrumbs={breadcrumbs}>
      <DataLoadSegment loading={loading || tradeLoading || profileLoading || marketsLoading || leverageLoading || marketLoading}>
        <Box display="flex" className={classes.box}>
          <Typography display={"inline"} className={classes.title} variant="h1">Position Details</Typography>
          <Button
            color="secondary"
            className={classes.button}
            startIcon={<TwitterIcon fontSize={'small'} className={classes.twitterIcon} />}
            onClick={() => {
              window.open(
                `https://twitter.com/intent/tweet?text=${shareMsg}&url=https://scan.carbon.network/position/${position?.address}/${position?.marketId}/${positionStatus.toLowerCase()}`,
                '',
                'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600',
              )
            }}
          >
            Share
          </Button>
        </Box>
        {position && marketDetails && (
          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12}>
              <div>
                <Typography variant="h3">
                  Position PNL History
                </Typography>
                <div id="position-history-chart" className={classes.chartContainer}>
                  {
                    positionHistoryLoading ? <LinearProgress />
                      : <div>
                        <PositionHistoryChart data={positionHistory} createdTime={createdTime} />
                      </div>
                  }
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <OrderUserDetails
                address={position.address}
                orderId={positionId}
                username={address}
                orderStatus={positionStatus}
                initiator={"N/A"}
                blockCreatedAt={createdTime?.toString() ?? '-'}
                blockHeight={Number(position.openedBlockHeight)}
                xHandle={twitter}
                isPosition={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <PositionDetails
                positionStatus={positionStatus}
                dollarValue={dollarValue}
                estLiquidation={estLiquidation}
                token={token}
                leverage={leverage}
                marketDetails={marketDetails}
                market={position.marketId}
                side={side}
                quantity={position.lots}
                allocatedMarginAmount={position.avgAllocatedMargin}
                demexPrice={demexPrice}
                currentPrice={currentPrice}
                collectiveTrades={collectiveTrades}
                positionFundingLoading={positionFundingLoading}
                fundingFee={positionFundingFee}
                entryPrice={position.avgEntryPrice}
                venue={"Demex"}
                realizedPnl={position.realizedPnl}
              />
            </Grid>
          </Grid>
        )}
        <Section className={classes.section}>
          <div className={classes.titleContainer}>
            <Typography variant={"h2"} display={"inline"} className={classes.title}> Related Trades </Typography>
            <Typography display={"inline"}> Include all the trades that filled this order</Typography>
          </div>
          {(trades && trades.length > 0) && <RelatedTrades trades={trades.slice((page - 1) * itemsPerPage, ((page - 1) * itemsPerPage) + itemsPerPage)} takerOrMaker={takerOrMaker} address={address} />}
          {(!trades || !trades.length) && (<TableEmptyState itemName={"trades"} />)}
          {trades && trades.length > 0 && (
            <Box paddingTop={3}>
              <PaginationByData
                data={trades}
                setPage={SetPage}
                page={page}
                itemsPerPage={itemsPerPage}
              />
            </Box>
          )}
        </Section>
      </DataLoadSegment>
    </Page>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  chartContainer: {
    background: 'white',
    padding: '1em 1.5em',
    marginTop: '0.5em',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '0.1em 0.1em'
    }
  },
  string: {
    wordBreak: 'break-all',
  },
  header: {
    flexBasis: '33%',
    paddingRight: '1rem',
  },
  title: {
    marginRight: theme.spacing(0.5)
  },
  titleContainer: {
    marginBottom: theme.spacing(1)
  },
  twitterIcon: {
    margin: theme.spacing(0, -0.5, 0, 0.5),
  },
  button: {
    borderRadius: 0,
    marginLeft: 'auto',
    backgroundColor: switcheo.twitter,
    padding: theme.spacing(1.5, 3),
    alignSelf: 'center',
    height: 'fit-content',
    fontWeight: 'bold',
    minWidth: 0,
    textAlign: 'center',
    color: 'white',
    wordBreak: 'normal',
    justifyContent: 'center',
    "&:hover": {
      backgroundColor: switcheo.twitterHover
    }
  },
  box: {
    marginBottom: theme.spacing(1.5)
  },
  section: {
    margin: theme.spacing(5, 0)
  },
  breadcrumb: {
    margin: theme.spacing(1, 0, 2, 0),
    color: theme.palette.text.primary,
  },
}))

export default Position
