import { Box, Theme, Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import axios from 'axios';
import { bnOrZero } from 'carbon-js-sdk/lib/util/number';
import 'chartjs-adapter-moment';
import { ChartContainer } from 'js/components';
import { CARBON_GENESIS_BLOCKTIME } from 'js/constants';
import { formatOpenInterestChartData } from 'js/helpers';
import { useAsyncTask, useRedux } from 'js/hooks';
import { switcheo } from 'js/theme/palettes/colors';
import { hexToRgba } from 'js/utils';
import moment from 'moment';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';

interface Props {
  market?: string[]
}

const OpenInterestChart: React.FunctionComponent<Props> = (props): ReactElement<{}> => {
  const { market } = props
  const sdk = useRedux((state) => state.core.carbonSDK)
  const [openInterestChartDataResponse, setOpenInterestChartDataResponse] = useState<any[]>([])
  const [getOpenInterestChart, loading] = useAsyncTask('getOpenInterestChart')
  const earliest = moment.max(moment(CARBON_GENESIS_BLOCKTIME), moment().subtract(7, 'days'))
  const [dateRange, setDateRange] = useState<any>({
    startDate: earliest.toDate(),
    endDate: moment().toDate(),
  })

  const chartDateCallback = (date: string) => {
    setDateRange(date)
  }

  useEffect(() => {
    if (loading) return
    if (!dateRange.startDate || !dateRange.endDate) return
    getOpenInterestChart(async () => {
      const networkPrefix = sdk?.network === 'testnet' ? 'test-' : ''
      const resp = await axios.get(`https://${networkPrefix}api-insights.carbon.network/position/openInterest?fromUnix=${moment(dateRange.startDate).unix()}&toUnix=${moment(dateRange.endDate).unix()}&market=${market}`)
      setOpenInterestChartDataResponse(resp.data.result.entries.rows)
      setCurrentIndex(resp.data.result.entries.rows.length - 1)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, market])


  const [chartLabels, chartData] = useMemo(() => {
    if (!openInterestChartDataResponse) return [[], []]
    let [labels, chartData] = formatOpenInterestChartData(openInterestChartDataResponse, market ? market[0] : '')
    chartData = chartData?.map((item: any, index: number) => {
      const color = hexToRgba(switcheo.chart[index % switcheo.chart.length], 0.90)
      const tokenLabel = item[0]
      return ({
        label: tokenLabel,
        fill: true,
        lineTension: 0.2,
        backgroundColor: color,
        borderColor: color,
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: color,
        pointBackgroundColor: color,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: color,
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
        data: item[1] ?? [],
        hidden: ["Others"].includes(item[0]) ? true : false,
      })
    })
    return [labels, chartData]
  }, [openInterestChartDataResponse, market])
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const totalOpenInterest = openInterestChartDataResponse[currentIndex]?.oi

  const classes = useStyles()
  const theme = useTheme()
  const isMobileSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileXs = useMediaQuery(theme.breakpoints.down('xs'))
  const maxXAxis = isMobileXs ? 3 : isMobileSm ? 5 : 10
  // const toolTipPosition = isMobileSm ? 'top' : 'right' as const
  const yTitleShowWhenMobile = isMobileXs ? false : true

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: true,
    },
    options: {
      legend: {
        display: false,
      }
    },
    scales: {
      x: {
        type: 'time' as 'time',
        time: {
          unit: 'hour' as 'hour',
          // displayFormats: {
          //   day: 'DD MMM',
          //   month: 'MMM YY'
          // },
          tooltipFormat: "lll",
        },
        ticks: {
          autoSkip: false,
          beginAtZero: true,
          alwaysShowLastTick: true,
          display: true,
          maxTicksLimit: maxXAxis,
          minRotation: 0,
          maxRotation: 0,
        },
        grid: {
          display: false,
        }
      },
      y: {
        stacked: true,
        title: {
          display: yTitleShowWhenMobile,
          text: 'Open Interest (USD)'
        },
        ticks: {
          callback: function (val: any) {
            return bnOrZero(val).toFormat()
          },
          display: true,
          minRotation: 0,
          maxRotation: 0,
        },
        grid: {
          display: true,
          tickBorderDash: [5, 5],
          borderDash: [5, 5],
        }
      }
    },
    plugins: {
      TimeScale: {
        x: {
          type: 'time'
        }
      },
      tooltip: {
        mode: 'index' as 'index',
        intersect: false,
        usePointStyle: true,
        pointStyle: 'circle',
        boxWidth: 10,
        boxPadding: 2,
        callbacks: {
          beforeTitle: function (context: any) {
            setCurrentIndex(context[0].dataIndex)
            return ""
          },
          label: function (context: any) {
            return context.dataset.label + ': $' + bnOrZero(context.dataset.data[context.dataIndex].y).toFormat()
          }
        }
      },
      crosshair: {
        line: {
          color: switcheo.text.grey,
          width: 2,
          dashPattern: [5, 5],
        }
      },
      // legend: {
      //   position: toolTipPosition as any,
      //   align: 'start' as const,
      //   fullSize: false,
      //   labels: {
      //     boxWidth: 20,
      //     boxHeight: 5,
      //   },
      // },
    },
  };

  const finalData = {
    labels: chartLabels ?? [],
    datasets: chartData ?? [],
  };


  return (
    <ChartContainer
      csvFileName={"openInterest"}
      taskNames={[]}
      onSelectDate={chartDateCallback}
      title={
        <Box className={classes.chartitleBox}>
          <Box>
            <Typography className={classes.statsTitle} variant={"h4"} component={"span"}>
              {`Open Interest`}
              {/* <TooltipHint title={"24h block rewards distributed to stakers, 10% of block rewards as community tax (SDF)"} className={classes.tooltip} /> */}
            </Typography>
          </Box>
          <Typography variant={"h3"} component={"span"}>
            {`$${bnOrZero(totalOpenInterest).toFormat(2)}`}
          </Typography>
        </Box>
      }
    >
      <Bar options={options} data={finalData} />
    </ChartContainer>
  )
}

const useStyles: any = makeStyles((theme: Theme) => ({
  statsTitle: {
    fontWeight: 'bold',
  },
  chartitleBox: {
    paddingBottom: theme.spacing(2),
  }
}))

export default OpenInterestChart