import { Box, BoxProps, FormControl, Grid, Input, InputLabel, makeStyles, TextField, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { setSubmitProposalFormState, setSubmitProposalFormValidationErrors } from 'js/actions/governance'
import { TooltipHint } from 'js/components'
import { MarketSelectInput } from 'js/components/form'
import { TutorialKeys } from 'js/constants'
import { useRedux } from 'js/hooks'
import { UpdateMarketProposalFormState } from 'js/models/Governance'
import { actions } from 'js/store'
import { BIG_ZERO, parseNumber } from 'js/utils'
import React, { ChangeEvent, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { UpdateMarketProposalInitialFormState } from '../constants'
import { getErrorMessages, getRequiredFieldsConstraints } from '../Helpers/InputConstraints'
import { updateFuturesMarketRequiredFields, updateSpotMarketRequiredFields } from './Helpers/FormConstraints'
import UpdateMarketProposalFuturesForm from './UpdateMarketProposalFuturesForm'

interface Props extends BoxProps {
}

const UpdateMarketProposalMainForm: React.FC<Props> = (props) => {
  const { className, ...rest } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const markets = useRedux((state) => state.app.markets)
  const formState = (useRedux((state) => state.governance.submitProposalFormState) || UpdateMarketProposalInitialFormState) as UpdateMarketProposalFormState

  useEffect(() => {
    dispatch(actions.Tutorial.triggerTutorial(TutorialKeys.SubmitProposal))
    dispatch(setSubmitProposalFormState(formState))
    validateRequiredFields(formState)
    // eslint-disable-next-line
  }, [])

  const validateRequiredFields = (formState: UpdateMarketProposalFormState) => {
    const requiredFields = formState.marketType === 'spot'
      ? updateSpotMarketRequiredFields
      : updateFuturesMarketRequiredFields
    const requiredFieldsConstraints = getRequiredFieldsConstraints(requiredFields)
    const errors = getErrorMessages(formState, requiredFieldsConstraints)
    dispatch(setSubmitProposalFormValidationErrors(errors))
  }

  const autoGenerateTitle = (formKey: keyof UpdateMarketProposalFormState, inputValue: string): string => {
    const name = formKey === 'id' ? inputValue : formState.id

    return `Update ${name} market`
  }

  const handleFormChange = (key: keyof UpdateMarketProposalFormState, isTitleInvolved?: boolean) => {
    return (event: ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string
      let newFormState = {
        ...formState,
        [key]: value,
        title: isTitleInvolved ? autoGenerateTitle(key, value) : formState.title,
      }

      if (key === 'id') {
        const market = markets[event.target.value as string]
        if (market) {
          const marketType = market.marketType as 'spot' | 'futures'
          if (marketType === 'spot') {
            newFormState = {
              ...UpdateMarketProposalInitialFormState,
              id: value,
              marketType,
              title: autoGenerateTitle(key, value),
            }
          }
          newFormState.displayName = market.displayName
          newFormState.marketType = marketType ?? newFormState.marketType
          newFormState.marketDescription = market.description ?? newFormState.marketDescription
          newFormState.minQuantity = market.minQuantity ?? newFormState.minQuantity
          newFormState.lotSize = market.lotSize ?? newFormState.lotSize
          newFormState.tickSize = market.tickSize ?? newFormState.tickSize

          if (marketType === 'futures') {
            newFormState.riskStepSize = parseNumber(market.riskStepSize, BIG_ZERO)!.toString() ?? newFormState.riskStepSize
            newFormState.initialMarginBase = parseNumber(market.initialMarginBase, BIG_ZERO)!.toString() ?? newFormState.initialMarginBase
            newFormState.initialMarginStep = parseNumber(market.initialMarginStep, BIG_ZERO)!.toString() ?? newFormState.initialMarginStep
            newFormState.maintenanceMarginRatio = parseNumber(market.maintenanceMarginRatio, BIG_ZERO)!.toString() ?? newFormState.maintenanceMarginRatio
            newFormState.tradingBandwidth = parseNumber(market.tradingBandwidth, BIG_ZERO)!.toString() ?? newFormState.tradingBandwidth
            newFormState.maxLiquidationOrderTicket = parseNumber(market.maxLiquidationOrderTicket, BIG_ZERO)!.toString() ?? newFormState.maxLiquidationOrderTicket
            newFormState.maxLiquidationOrderDuration = parseNumber(market.maxLiquidationOrderDuration?.seconds.toNumber(), BIG_ZERO)!.toString() ?? newFormState.maxLiquidationOrderDuration
            newFormState.impactSize = parseNumber(market.impactSize, BIG_ZERO)!.toString() ?? newFormState.impactSize
            newFormState.markPriceBand = parseNumber(market.markPriceBand, BIG_ZERO)!.toString() ?? newFormState.markPriceBand
            newFormState.lastPriceProtectedBand = parseNumber(market.lastPriceProtectedBand, BIG_ZERO)!.toString() ?? newFormState.lastPriceProtectedBand
            newFormState.maxOpenInterest = parseNumber(market.maxOpenInterest, BIG_ZERO)!.toString() ?? newFormState.maxOpenInterest
            newFormState.staleIndexPriceAllowance = parseNumber(market.staleIndexPriceAllowance?.seconds.toNumber(), BIG_ZERO)!.toString() ?? newFormState.staleIndexPriceAllowance
            newFormState.creator = market.creator ?? newFormState.creator
            // TODO: add indexOracleId, isSettled, closedBlockHeight
          }
        }
      }

      dispatch(setSubmitProposalFormState(newFormState))
      validateRequiredFields(newFormState)
    }
  }

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <MarketSelectInput value={formState.id} onChange={handleFormChange('id', true)} />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel shrink>
              Display Name
              <TooltipHint title={'Mutable name of market'} />
            </InputLabel>
            <Input
              fullWidth
              value={formState.displayName}
              onChange={handleFormChange('displayName')}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            type="number"
            rows={2}
            label="Market Description"
            InputLabelProps={{ shrink: true }}
            value={formState.marketDescription}
            onChange={handleFormChange('marketDescription')}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            type="number"
            label="Lot Size"
            InputLabelProps={{ shrink: true }}
            value={formState.lotSize}
            onChange={handleFormChange('lotSize')}
            placeholder='1'
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            type="number"
            label="Min Order Quantity (multiple of Lot Size)"
            InputLabelProps={{ shrink: true }}
            value={formState.minQuantity}
            onChange={handleFormChange('minQuantity')}
            placeholder='10'
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            type="number"
            label="Tick Size"
            InputLabelProps={{ shrink: true }}
            value={formState.tickSize}
            onChange={handleFormChange('tickSize')}
            placeholder='0.1'
          />
        </Grid>
      </Grid>
      {formState.marketType === 'futures' && (
        <UpdateMarketProposalFuturesForm formState={formState} />
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2, 0),
    },
  },
  btn: {
    // minWidth: "10rem",
  },
  datetimePicker: {
    '& .MuiPickersModal-dialogRootWider': {
      maxWidth: '20rem',
    },
    '& .MuiPickersBasePicker-pickerView': {
      maxWidth: 'unset',
    },
    '& .MuiPickerDTToolbar-toolbar': {
      '& .MuiPickersToolbarText-toolbarTxt': {
        '&.MuiTypography-subtitle1': {
          ...theme.typography.subtitle1,
          fontSize: '1rem',
          maxHeight: '1.85rem',
        },
        '&.MuiTypography-h4': {
          ...theme.typography.h4,
          fontSize: '2rem',
          lineHeight: 1.17,
          letterSpacing: '0.00735rem',
          fontWeight: 600,
        },
        '&.MuiTypography-h3': {
          ...theme.typography.h3,
          fontSize: '3rem',
          lineHeight: 1.04,
          letterSpacing: '0rem',
        },
      },
    },
    '& .MuiPickerDTTabs-tabs': {
      border: '1px solid transparent',
      '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    '& .MuiPickersYearSelection-container': {
      '& .MuiPickersYear-root': {
        fontSize: '1.25rem',
        height: '2.375rem',
        '&.MuiPickersYear-yearSelected': {
          fontWeight: 600,
          fontSize: '1.6rem',
        },
      },
    },
    '& .MuiDialogActions-root': {
      '& >button': {
        padding: theme.spacing(1, 1.75),
        minWidth: 'unset',
        textTransform: 'initial',
      },
    },
  },
  label: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "1rem",
  },
  warningIcon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
  menuItems: {
    '&:hover, &.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  formControl: {
    marginRight: theme.spacing(1),
    minWidth: 120,
  },
}))

export default UpdateMarketProposalMainForm
